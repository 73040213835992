import { useState, useEffect, useContext } from 'react';

import usePrevious from "../../hooks/usePrevious";
import GlobalContext from '../../store/global-context';

import { cookieScreens, cookieTypeEnum, messageTypes } from '../../configs/enums';
import DocCookies from '../../libs/doc.cookies';
import utils from '../../utils/utils';

import './Cookies.scss';

import CookiesPolicy from './CookiesPolicy';
import CookiesSettings from './CookiesSettings';

const Cookies = () => {
    const { globalCtx } = useContext(GlobalContext);

    const [acceptedCookies, setAcceptedCookies] = useState({
        functional: true,
        performance: false,
        behavioural: false
    });

    // ToDo: create enum
    const [formScreen, setFormScreen] = useState(cookieScreens.hidden);
    const previousFormScreen = usePrevious(formScreen);

    useEffect(() => {

        // Check if there is an existing cookie
        const cookie = DocCookies.getItem('cookieConsent');

        // Accepted before AND not old type of cookies
        if (utils.isJsonObject(cookie)) {
            clearNotAllowedCookies(cookie);

            // send acceptedBefore message to parent
            let dataForTargetPage = {
                name: 'easymarkets_widget_message_cookies_acceptedBefore',
                type: messageTypes.parentWindow
            };
            utils.sendMessageToFrameParent(dataForTargetPage);
            // New user / old cookie type - ask 
        } else {

            // show CookiesPolicy screen
            setFormScreen(cookieScreens.policy);
        }
    }, []);

    // Send message to open modal, if formScreen was hidden
    useEffect(() => {
        // ToDo: we can add more flexible way here to handle cultures, e.g. via url params
        if (globalCtx.culture !== 'eu') return;

        if (formScreen && (previousFormScreen === cookieScreens.hidden)) {
            openModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formScreen]);

    /** run this each time, to cover cases when client changed his mind */
    const clearNotAllowedCookies = (cookie) => {
        if (typeof cookie !== 'object') {
            cookie = JSON.parse(cookie);
        }

        if (!cookie.behavioural) {
            cookieTypeEnum.behavioural.forEach(DocCookies.removeItem);
        }

        if (!cookie.performance) {
            cookieTypeEnum.performance.forEach(DocCookies.removeItem);
        }
    };

    const switchScreen = (event) => {
        setFormScreen(Number(event.target.dataset.nextScreen));
    };
    const openModal = () => {
        // send acceptedBefore message to parent
        let dataForTargetPage = {
            name: 'easymarkets_widget_message_cookies_openModal',
            type: messageTypes.parentWindow,
        };
        utils.sendMessageToFrameParent(dataForTargetPage);
    };
    const closeModal = () => {
        // send acceptedBefore message to parent
        let dataForTargetPage = {
            name: 'easymarkets_widget_message_cookies_closeModal',
            type: messageTypes.parentWindow,
        };
        utils.sendMessageToFrameParent(dataForTargetPage);
    };
    const handleCookiesSave = (_acceptedCookies) => {
		DocCookies.setItem('cookieConsent', JSON.stringify(_acceptedCookies), 31536e3, '/', '.easymarkets.com');
		DocCookies.setItem('cookieConsentDate', new Date().toISOString().slice(0, 19), 31536e3, '/', '.easymarkets.com');

        closeModal();
    };

    return (
        <>
            {formScreen === cookieScreens.policy ? <CookiesPolicy onAccept={handleCookiesSave} setAcceptedCookies={setAcceptedCookies} onOpenSettingsClick={switchScreen} /> : null}
            {formScreen === cookieScreens.settings ? <CookiesSettings onAccept={handleCookiesSave} acceptedCookies={acceptedCookies} setAcceptedCookies={setAcceptedCookies} /> : null}
        </>
    );
};

export default Cookies;