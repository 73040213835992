import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import getTermsConditions from "../../utils/getTermsConditions";
import GlobalContext from "../../store/global-context";
import utils from "../../utils/utils";
import LegalDocuments from "../../libs/legal.documents";

const TermsConditions = () => {
    const { globalCtx } = useContext(GlobalContext);
    const { t } = useTranslation();

    const [privacyPolicyHref, setPrivacyPolicyHref] = useState('');
    const [termsConditionsHref, setTermsConditionsHref] = useState('');

    useEffect(() => {
        //LegalDocuments.updateLegalDocumentUrls('data-legal-document', globalCtx.language, globalCtx.culture);
        async function fetchMyAPI() {
            console.log(`Fetching documents for ${globalCtx.language}, ${globalCtx.culture}`);
            let url = await LegalDocuments.getLegalDocumentUrls('privacy-policy', globalCtx.language, globalCtx.culture);
            setPrivacyPolicyHref(url);
            url = await LegalDocuments.getLegalDocumentUrls('client-agreement', globalCtx.language, globalCtx.culture);
            setTermsConditionsHref(url);
        }
        fetchMyAPI();
    }, [globalCtx.language, globalCtx.culture]);

    let terms = getTermsConditions(globalCtx.culture);
    if (!terms) {
        return ('Not Found');
    }

    let text = t(terms);

    // replace constants
    text = utils.reactStringReplace(text, `%%PRIVACY_POLICY%%`, <a key="PRIVACY_POLICY" href={privacyPolicyHref} data-legal-document="privacy-policy" rel="noreferrer" target="_blank">{t('privacy_policy')}</a>);
    text = utils.reactStringReplace(text, `%%TERMS_CONDITIONS%%`, <a key="TERMS_CONDITIONS" href={termsConditionsHref} data-legal-document="client-agreement" rel="noreferrer" target="_blank" >{t('terms_conditions')}</a>);

    return (<p className="text-normal">{text}</p>);
};

export default TermsConditions;