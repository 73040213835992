import './Login.scss';
import { useSearchParams } from 'react-router-dom';
import Header from '../components/Header';
import LoginForm from './LoginForm';

const Login = () => {

    const [searchParams] = useSearchParams();

    const params = Object.fromEntries(Object.entries({
        title: searchParams.get("title"),
        showHeader: searchParams.get("showHeader"),
        showSponsorship: searchParams.get("showSponsorship"),
    }).filter(([_, value]) => value !== null));

    return (
        <div id='loginWidget' className='pb-3'>
            {params.showHeader && <Header title={params.title}/>}
            <LoginForm />
        </div>
    );
};

export default Login;