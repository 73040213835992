import WebApis from "../apis/WebApis";
import enums from "../configs/enums";

const LegalDocuments = {
    /**
     * Find all legal documents and insert href with correct file name, based on language and culture
     * @param {*} target 
     * @param {*} language 
     * @param {*} culture 
     */
    updateLegalDocumentUrls: async (target, language, culture) => {
        // get correct documents from db
        let legalDocuments = await WebApis.getLegalDocuments(language);

        // will be populated by processed document types
        let processed = [];

        let elements = document.querySelectorAll('[' + target + ']');

        for (let i = 0; i < elements.length; i++) {

            // document type
            let type = elements[i].getAttribute(target);

            // filter out duplicates / already processed
            if (processed.indexOf(type) === -1) {

                // flag as processed
                processed.push(type);

                // Search legalDocuments object
                // (legalDocuments found in page body (bottom))
                if (type in legalDocuments[culture]) {

                    let document_object = legalDocuments[culture][type];
                    let url = 'https://' + process.env.REACT_APP_LOCATION_PULSE + '/assets/assets/view/' + document_object.file;

                    let update_elements = Array.prototype.slice.call(document.querySelectorAll('[' + target + '=' + type + ']'));

                    // forEach.... hmmmmm.... compatibilty
                    if (update_elements.length > 0) {

                        update_elements.forEach(function (el) {
                            el.href = url;
                        });
                    }
                }
            }
        }

    },

    /**
     * Find all legal documents and insert href with correct file name, based on language and culture
     * @param {*} target 
     * @param {*} language 
     * @param {*} culture 
     */
    getLegalDocumentUrls: async (type, language, culture) => {
        // get correct documents from db
        let legalDocuments = await WebApis.getLegalDocuments(language);
        let url = null;

        if (type in legalDocuments[culture]) {

            let document_object = legalDocuments[culture][type];
            url = 'https://' + process.env.REACT_APP_LOCATION_PULSE + '/assets/assets/view/' + document_object.file;
        }

        return url;
    },

    getAcceptTermsInfo: async (type, language, culture) => {
        let result = {
            acceptTerms: false,
            termsVersion: ''
        };

        // not accept terms and conditions for these cultures
        if ([enums.cultureEnum.eu, enums.cultureEnum.au].includes(culture)) {
            return result;
        };

        // get correct documents from db
        let legalDocuments = await WebApis.getLegalDocuments(language);

        if (type in legalDocuments[culture]) {

            let document_object = legalDocuments[culture][type];

            result = {
                acceptTerms: true,
                termsVersion: document_object.version
            };

        }

        return result;
    }
};

export default LegalDocuments;