import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';

import defaultValues from "../../configs/defaultValues";

const Sponsorship = () => {
    const { i18n } = useTranslation();

    let imgSource = '';
    try {
        imgSource = require(`./../../img/realmadrid-sponsor-${i18n.language}.png`);
    } catch (error) {
        imgSource = defaultValues.sponsorLogo;
        console.warn(`Get img for Sponsorship failed. Placed default value. ${error}`);
    }

    return (
        <Row className='register-sponsorship'>
            <Col>
                <img src={imgSource} className="register-sponsorship-img mx-auto mb-2 d-block mw-100" alt="real madrid partner" />
            </Col>
        </Row>
    );
}

export default Sponsorship;