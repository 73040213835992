import { useTranslation } from "react-i18next";

import { Row, Col } from 'react-bootstrap'

const Header = ({ title, ...restProps }) => {
    const { t } = useTranslation();

    return (
        <Row {...restProps}>
            <Col>
                <h2>{t(title ?? 'sign_up_now')}</h2>
            </Col>
        </Row>
    );
}

export default Header;