/**
 * This file contains settings of available widgets to create
 * 
 *  @property {String} value - will be showed in available widgets list in the form
 *  @property {array of Objects} params - contains fields of the form (for now all of them are required to complete the form)
 *  @property {array} options - contain options for the widget (not required to complete the form)
 */

const widgets = {
    'register': {
        key: 'register',
        value: 'Register Widget',
        /**
         * @param key will be used for dynamic component registration
         * @param type same as component name (used for switch conditions)
         * @param props will be destructed and sended as props to component. Please find all custom available props in each component defenition.
         */
        params: [
            {
                key: 'gateway',
                type: 'TextField',
                props: {
                    label: 'Affiliate Gateway',
                    message: 'You can find this in the affiliate system.',
                    placeholder: 'Affiliate Gateway',
                    required: true
                }
            },
            {
                key: 'utm_medium',
                type: 'TextField',
                props: {
                    label: 'utm_medium',
                }
            },
            {
                key: 'title',
                type: 'TextField',
                props: {
                    label: 'Title for widget',
                    message: 'This will appear at the top of the widget.',
                }
            },
            {
                key: 'language',
                type: 'SelectBox',
                getOptionsKey: 'langs',
                props: {
                    disableDefault: true,
                    label: 'Language',
                    required: true
                }
            },
            // {
            //     key: 'layout',
            //     type: 'SelectBox',
            //     getOptionsKey: 'layouts',
            //     props: {
            //         disableDefault: true,
            //         label: 'Layout',
            //         disabled: true,
            //         message: 'Comming soon...'
            //     },
            // },
            {
                key: 'showHeader',
                type: 'CheckBox',
                defaultChecked: true,
                props: {
                    label: 'Show Header',
                }
            },
            {
                key: 'showSocial',
                type: 'CheckBox',
                props: {
                    label: 'Show Social',
                }
            },
            {
                key: 'showSponsorship',
                type: 'CheckBox',
                props: {
                    label: 'Show Sponsorship',
                }
            },
            {
                key: 'showTrustpilot',
                type: 'CheckBox',
                props: {
                    label: 'Show Trustpilot',
                }
            },
        ]
    },
    'login': {
        key: 'login',
        value: 'Login Widget',
        params: [
            {
                key: 'utm_medium',
                type: 'TextField',
                props: {
                    label: 'utm_medium',
                }
            },
            {
                key: 'title',
                type: 'TextField',
                props: {
                    label: 'Title for widget',
                    message: 'This will appear at the top of the widget.',
                }
            },
            {
                key: 'showHeader',
                type: 'CheckBox',
                defaultChecked: true,
                props: {
                    label: 'Show Header',
                }
            },
        ]
    },
    'cookies': {
        key: 'cookies',
        value: 'Cookies Consent Widget',
        /**
         * @param key will be used for dynamic component registration
         * @param type same as component name (used for switch conditions)
         * @param props will be destructed and sended as props to component. Please find all custom available props in each component defenition.
         */
        params: [
            {
                key: 'language',
                type: 'SelectBox',
                getOptionsKey: 'langs',
                props: {
                    disableDefault: true,
                    label: 'Language',
                    required: true
                }
            },
            {
                key: 'culture',
                type: 'SelectBox',
                getOptionsKey: 'cultures',
                props: {
                    disableDefault: true,
                    label: 'Culture',
                    required: true
                }
            }
        ]
    }
};

export default widgets;