import { Link } from 'react-router-dom';

const NavBar = () => {

	return (
		<nav>
			<ul>
				<li><Link to="/">Home</Link></li>
				<li><Link to="/admin">Admin</Link></li>
				<li><Link to="/tests/testapi">Test API</Link></li>
			</ul>
		</nav>
	)

}

export default NavBar;