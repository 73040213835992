/**
 * Required props are:
 * - value
 * 
 * Available options is: 
 * - label
 * - message
 * 
 * Rest props will be applied on container
 */
import { useTranslation } from "react-i18next";

export default function CheckBox(props) {
    const { t } = useTranslation();

    const { value, label, message, onChange, checked, defaultChecked, ...restProps } = props;

    return (
        <div className="form-check"  {...restProps}>
            {label && <label className="form-check-label">{t(label)}</label>}
            <input value={value} onChange={onChange} checked={checked} defaultChecked={defaultChecked} type="checkbox" className="form-check-input" />
            {message && <small className="form-text text-muted">{message}</small>}
        </div>
    );
}