/**
 * Simple link injection
 *
 * Takes link source and ijects it with attributes
 * calls onload callback if supplied.
 *
 * @param {string} href link source to load.
 * @param {string} rel Relationships between the current document and an external resource.
 * @param {function=} onload Callback function.
 * @param {object=} attributes will be destructed as atrributes for new link.
 * @return {void}
 */
const injectLink = (href, rel, attributes = {}, onload = null) => {

	if (!href || typeof href !== 'string') {
		throw new Error('Invalid source (href) for link injection');
	}

	if (!rel || typeof rel !== 'string') {
		throw new Error('Invalid relation for link injection');
	}

	const firstLink = document.getElementsByTagName('link')[0];
	const newLink = document.createElement('link');

	// Set callback
	if (typeof onload === 'function') {
		newLink.onload = onload;
	}

	// Set required attributes
	newLink.href = href;
	newLink.rel = rel;

	// set additional attributes
	for (const attr in attributes) {
		newLink[attr] = attributes[attr];
	}

	// Inject script
	firstLink.parentNode.insertBefore(newLink, firstLink);
};

export default injectLink;
