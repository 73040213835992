import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import LegalDocuments from '../../libs/legal.documents';
import GlobalContext from '../../store/global-context';

const CookiesSettings = ({ onAccept, acceptedCookies, setAcceptedCookies }) => {
    const { t } = useTranslation();
    const { globalCtx } = useContext(GlobalContext);

    const [cookiesPolicyUrl, setCookiesPolicyUrl] = useState('');

    useEffect(() => {
        LegalDocuments.getLegalDocumentUrls('cookies', globalCtx.language, globalCtx.culture).then((url) => {
            setCookiesPolicyUrl(url);
        });
    }, [globalCtx.language, globalCtx.culture]);

    const sponsorshipLogoPath = '/cookies/sponsorship-2023.png';
    const inactiveToggleImgPath = '/cookies/eM_InactiveToggle.svg';
    const activeToggleImgPath = '/cookies/eM_ActiveToggle.svg';

    const getImagePath = (isActive) => {
        return isActive ? activeToggleImgPath : inactiveToggleImgPath;
    };
    const onToggleClick = (event) => {
        setAcceptedCookies({
            ...acceptedCookies,
            [event.target.dataset.name]: !acceptedCookies[event.target.dataset.name]
        });
    };
    const acceptAllCookies = () => {
        const _acceptedCookies = {
            functional: true,
            performance: true,
            behavioural: true
        };

        setAcceptedCookies(_acceptedCookies);
        onAccept(_acceptedCookies);
    };
    const savePreferences = () => {
        onAccept(acceptedCookies);
    };


    return (
        <div id="cookiesSettings" className="row m-0 pt-0 pt-xl-4">
            <div className="d-flex col-xxl-5 p-0">
                <div className="row">
                    <div className="col-xl-8 align-items-center d-flex pb-4">
                        <div className="sponsorship-logo">
                            <img data-src={sponsorshipLogoPath} className="d-block col-xl-12 col-9" alt="Official sponsor of Real Madrid." src={sponsorshipLogoPath} />
                        </div>
                    </div>
                    <div className="col-xl-12 align-items-center">
                        <p className="pb-0 mb-0 d-sm-block d-none fw-bold" style={{ fontSize: 16 }}>{t('Manage your cookie settings')}</p>
                        <p className="pb-0 mb-0 d-block d-sm-none fw-bold" style={{ fontSize: 17 }}>{t('Manage your cookie settings')}</p>
                        <p className="fw-normal" style={{ fontSize: 13 }}>{t('Select which cookies types you wish to enable')}</p>
                        <p className="fw-normal" style={{ fontSize: 13 }}>{t('To learn more, please visit our')} <a style={!cookiesPolicyUrl ? { pointerEvents: "none" } : null} href={cookiesPolicyUrl} data-legal-document="cookies" rel="noreferrer" target="_blank">{t('Cookies Policy')}</a></p>
                    </div>
                    <div className="col-xl-12 d-flex align-items-end py-4 text-left">
                        <button onClick={acceptAllCookies} className="col-12 col-xl-8 py-2 py-xl-2 fw-bolder btn btn-success btn-consent" style={{ fontSize: 13 }}>{t('Accept All')}</button>
                    </div>
                </div>
            </div>
            <div className="d-flex col-xxl-7 pb-4 pb-sm-0 px-0 px-xxl-2">
                <hr className="d-none d-xl-block" style={{ opacity: 1, width: 1, margin: '0 0.5em', background: '#dee2e6', }} />
                <div className="row">
                    <div className="col-xl-12 align-items-center" style={{ minHeight: 223 }}>
                        <div className="row">
                            <div className="col-xl-9 col-9 align-items-center ps-4">
                                <p className="pb-0 mb-0 fw-bold" style={{ fontSize: 12 }}>{t('Functional & Necessary')} {t('(always required)')}</p>
                                <p className="fw-normal" style={{ fontSize: 12 }}>{t('Essential cookies that allow our website to function correctly')}</p>
                            </div>
                            <div className="d-flex justify-content-center col-xl-3 col-3 align-items-center">
                                <img className="d-block col-xl-9 pt-2 pt-xl-0 opacity-50 cookie-toggle" src={getImagePath(acceptedCookies.functional)} data-name="functional" alt="Accepted" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-9 col-9 align-items-center ps-4">
                                <p className="pb-0 mb-0 fw-bold" style={{ fontSize: 12 }}>{t('Performance')}</p>
                                <p className="fw-normal" style={{ fontSize: 12 }}>{t("Performance cookies improve our website's functionallity and user experience")}</p>
                            </div>
                            <div className="d-flex justify-content-center col-sm-3 col-3 align-items-center">
                                <input type="checkbox" id="performance_checkbox" className="d-none" />
                                <img className="d-block col-xl-9 pt-2 pt-xl-0 cookie-toggle" src={getImagePath(acceptedCookies.performance)} onClick={onToggleClick} data-name="performance" alt="Accepted" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-9 col-9 align-items-center ps-4">
                                <p className="pb-0 mb-0 fw-bold" style={{ fontSize: 12 }}>{t('Behavioral Advertising')}</p>
                                <p className="fw-normal" style={{ fontSize: 12 }}>{t('These cookies assist in providing you with more relevant advertising')}</p>
                            </div>
                            <div className="d-flex justify-content-center col-xl-3 col-3 align-items-center">
                                <input type="checkbox" id="behavioural_checkbox" className="d-none" />
                                <img className="d-block col-xl-9 pt-2 pt-xl-0 cookie-toggle" src={getImagePath(acceptedCookies.behavioural)} onClick={onToggleClick} data-name="behavioural" alt="Accepted" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-auto d-flex align-items-start py-4 text-left">
                        <button onClick={savePreferences} className="col-12 col-xl-auto w-xl-100 py-2 fw-bolder btn btn-save-preferences text-white">{t('Save Preferences')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesSettings;