import { useTranslation } from "react-i18next";

import { Row, Col, Button, Form } from 'react-bootstrap';

const CountrySelect = ({value, onChange, submitText, onSubmit, disableSubmit, items}) => {
    const { t } = useTranslation();

    return (
        <div className="mx-auto">
            <Row>
                <Col>
                    <Form.Select
                        className="register-form-control"
                        value={value}
                        onChange={onChange}
                    >
                        {items.map(country => {
                            return (
                                <option value={country.countryId} key={country.countryId}>{t(country.country, { ns: 'countries' })}</option>
                            )
                        })}
                    </Form.Select>
                    <small className="help-block d-inline-block mb-2">{t('country_of_residence_help_message')}</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid">
                        <Button className="button btn-primary" name="button_register_social" onClick={onSubmit} disabled={disableSubmit}>
                            {submitText}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default CountrySelect;