import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import defaultValues from "./configs/defaultValues";

// Importing translation files
import translationENG from "./locales/eng/translation.json";
import translationARA from "./locales/ara/translation.json";
import translationDEU from "./locales/deu/translation.json";
import translationSPA from "./locales/spa/translation.json";
import translationITA from "./locales/ita/translation.json";
import translationJPN from "./locales/jpn/translation.json";
import translationPOL from "./locales/pol/translation.json";
import translationPOR from "./locales/por/translation.json";
import translationZHO from "./locales/zho/translation.json";

import countriesENG from "./locales/eng/countries.json";
import countriesARA from "./locales/ara/countries.json";
import countriesDEU from "./locales/deu/countries.json";
import countriesSPA from "./locales/spa/countries.json";
import countriesITA from "./locales/ita/countries.json";
import countriesJPN from "./locales/jpn/countries.json";
import countriesPOL from "./locales/pol/countries.json";
import countriesPOR from "./locales/por/countries.json";
import countriesZHO from "./locales/zho/countries.json";

// https://medium.com/how-to-react/setup-multilingual-in-react-js-using-i18n-module-33b1bfbb57cd

//Creating object with the variables of imported translation files
const resources = {
    ara: {
        translation: translationARA,
        countries: countriesARA,
    },
    deu: {
        translation: translationDEU,
        countries: countriesDEU,
    },
    eng: {
        translation: translationENG,
        countries: countriesENG,
    },
    ita: {
        translation: translationITA,
        countries: countriesITA,
    },
    jpn: {
        translation: translationJPN,
        countries: countriesJPN,
    },
    pol: {
        translation: translationPOL,
        countries: countriesPOL,
    },
    por: {
        translation: translationPOR,
        countries: countriesPOR,
    },
    spa: {
        translation: translationSPA,
        countries: countriesSPA,
    },
    zho: {
        translation: translationZHO,
        countries: countriesZHO,
    },
};

//i18N Initialization

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'eng', //default language
        fallbackLng: defaultValues.fallbackLanguage,
        returnEmptyString: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
