import { useState } from 'react';

const useModal = () => {
    const [showModal, setShowModal] = useState(false);

    /**
     * You can forse new state with parameter or toggle to opposite if no specified 
     * @param {Boolean} newState 
     */
    const toggleModal = (newState) => {
        setShowModal(newState ?? !showModal);
    }

    return {
        showModal,
        toggleModal,
    }
};

export default useModal;
