import getErrorMessage from "../configs/apiErrors";
import Execute from "./Execute";

const Apis = {

	CheckExistUserName: async (userName) => {

		let params = {
			method: "GET",
			api: 'CheckExistUserName',
			args: {
				userName: userName.toLowerCase(),
				applicationId: process.env.REACT_APP_EASYMARKETS_APP_ID,
			}
		};

		let result = JSON.parse(await Execute(params));
		let errorFound = result.results[0].CheckExistUserName.emailError;
		if (errorFound) {
			console.log(getErrorMessage(errorFound));
			return true;
		}
		console.log('Email does not exists');
		return false;

	},

	GetEuRiskPercentage: async (userName) => {

		let params = {
			method: "GET",
			api: 'EasyMarkets.GetEuRiskPercentage',
		};

		let result = JSON.parse(await Execute(params));
		let error = result.results[0]['EasyMarkets.GetEuRiskPercentage'].error;
		if (error) {
			console.log(`Api error: ${error}, aborting`);
			return false;
		}
		let euRiskPercentage = result.results[0]['EasyMarkets.GetEuRiskPercentage'].euRiskWarningPercentage;

		return euRiskPercentage ?? '';

	},

	/**
	 * UNFINISHED: Check
	 * 
	 * Use isSocial = true if you are creating a new account via social buttons.
	 * 
	 * Properties in quotes means that they are the same with API keys
	 * 
	 * @param {*} input 
	 * @param {Boolean} isSocial - set true to use socialMediaToken and socialMediaType instead of password and phone
	 * @returns destructured result from CreateOrUpdateAccount
	 */
	CreateOrUpdateAccount: async (input, isSocial) => {
		// see lnd.easymarkets.com/widgets/bootstrap-3/WidgetSignupForm/assets/js/signup.js
		let createOrUpdateDefaults = {
			'enableReceiveNewsletters': true,
			'cultureId': input.cultureId,
			'progressStep': 1,
			'isOverEighteen': true,
			'isDemo': false,
			'regType': 0,
			'isProductDisclosureAccepted': false,
			'sendEmailType': 1,
			'doLoginOnCreate': true,
		};

		let additionalArgs = () => {
			let args = {};

			if (isSocial) {
				args = {
					'socialMediaType': input.socialMediaType,
					'socialMediaToken': input.socialMediaToken,
				};
			} else {
				args = {
					'password': input.password,
					'countryCode': input.phone_code,
					'phone1': input.phone,
				};
			}

			return args;
		};

		let params = {
			method: "GET",
			api: 'CreateOrUpdateAccount',
			args: {
				...createOrUpdateDefaults,

				'countryId': input.countryId,

				'firstName': input.first_name,
				'lastName': input.last_name,
				'email': input.email.toLowerCase(),
				'userName': input.email.toLowerCase(),
				'gatewayId': input.gatewayId,
				'gatewaySubId': input.gatewaySubId,
				'gatewayLprId': input.gatewayLprId,
				// It's need to be stringified before sending
				'utmParams': JSON.stringify(input.utm_params),
				'acceptTerms': input.acceptTerms ?? false,
				'termsVersion': input.termsVersion ?? '',
				
				...additionalArgs()
			}
		};

		let result = JSON.parse(await Execute(params));

		let error = result.results[0].CreateOrUpdateAccount.error;
		if (error) {
			console.warn(`Api error: ${JSON.stringify(error)}, description: ${getErrorMessage(error)}, aborting`);
		} else {
			console.log('User created successfully');
		}

		return { ...result.results[0].CreateOrUpdateAccount };
	},
	/**
	 * Used to check if user already registred with social account
	 * 
	 * @param {*} input - socialMediaType, socialMediaToken, applicationId
	 * @returns destructured result from Login
	 */
	Login: async (input) => {
		const { socialMediaToken, socialMediaType } = input;

		let params = {
			method: "GET",
			api: 'Login',
			"args":{
				socialMediaType,
				socialMediaToken,
				
				"applicationId": process.env.REACT_APP_EASYMARKETS_APP_ID
			 }
		};

		let result = JSON.parse(await Execute(params));

		let error = result.results[0].Login.error;
		if (error) {
			console.warn(`Api error: ${JSON.stringify(error)}, description: ${getErrorMessage(error)}, aborting`);
		}

		return { ...result.results[0].Login };
	},
	// @TODO use dynamic application ID
	LoginToEasyMarkets: async (input) => {
		const { userName, password } = input;

		const params = {
			method: "GET",
			api: 'Login',
			"args":{
				userName,
				password,
				"applicationId": process.env.REACT_APP_EASYMARKETS_APP_ID
			 }
		};

		const result = JSON.parse(await Execute(params));
		const error = result.results[0].Login.error;
		if (error) {
			console.warn(`Api error: ${JSON.stringify(error)}, description: ${getErrorMessage(error)}, aborting`);
		}
		return { ...result.results[0].Login };
	}
};

export default Apis;
