import { createContext } from 'react';

const GlobalContext = createContext({
    language: '',
    cultureId: null,
    culture: '',
    country: {},
    acceptTerms: null,
    termsVersion: '',
    widgetWidth: null,
    widgetHeight: null,
    // We also need to assign setter functions, and define it from setState
    setGlobalCtx: () => {}
});

export default GlobalContext;