// Copied from app/Lib/FormData/EasyMarketsCountries.php

// To get list of countries from eM project (class EasyMarketsCountries.php) and convert it to JS you can use e.g. 
// `echo json_encode(...)`
// And copy text from web page
// ¯\_(ツ)_/¯

const countries = [{
    "countryId": 249,
    "country": "Afghanistan",
    "iso": "AF",
    "countryCode": 93,
    "culture": "int"
}, {
    "countryId": 1,
    "country": "Albania",
    "iso": "AL",
    "countryCode": 355,
    "culture": "int"
}, {
    "countryId": 2,
    "country": "Algeria",
    "iso": "DZ",
    "countryCode": 213,
    "culture": "int"
}, {
    "countryId": 4,
    "country": "Andorra",
    "iso": "AD",
    "countryCode": 376,
    "culture": "int"
}, {
    "countryId": 5,
    "country": "Angola",
    "iso": "AO",
    "countryCode": 244,
    "culture": "int"
}, {
    "countryId": 6,
    "country": "Anguilla",
    "iso": "AI",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 7,
    "country": "Antarctica",
    "iso": "AQ",
    "countryCode": 672,
    "culture": "int"
}, {
    "countryId": 8,
    "country": "Antigua and Barbuda",
    "iso": "AG",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 9,
    "country": "Argentina",
    "iso": "AR",
    "countryCode": 54,
    "culture": "int"
}, {
    "countryId": 10,
    "country": "Armenia",
    "iso": "AM",
    "countryCode": 374,
    "culture": "int"
}, {
    "countryId": 11,
    "country": "Aruba",
    "iso": "AW",
    "countryCode": 297,
    "culture": "int"
}, {
    "countryId": 12,
    "country": "Australia",
    "iso": "AU",
    "countryCode": 61,
    "culture": "au"
}, {
    "countryId": 13,
    "country": "Austria",
    "iso": "AT",
    "countryCode": 43,
    "culture": "eu"
}, {
    "countryId": 14,
    "country": "Azerbaijan",
    "iso": "AZ",
    "countryCode": 994,
    "culture": "int"
}, {
    "countryId": 15,
    "country": "Bahamas",
    "iso": "BS",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 16,
    "country": "Bahrain",
    "iso": "BH",
    "countryCode": 973,
    "culture": "int"
}, {
    "countryId": 17,
    "country": "Bangladesh",
    "iso": "BD",
    "countryCode": 880,
    "culture": "int"
}, {
    "countryId": 18,
    "country": "Barbados",
    "iso": "BB",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 19,
    "country": "Belarus",
    "iso": "BY",
    "countryCode": 375,
    "culture": "syc"
}, {
    "countryId": 20,
    "country": "Belgium",
    "iso": "BE",
    "countryCode": 32,
    "culture": "eu"
}, {
    "countryId": 21,
    "country": "Belize",
    "iso": "BZ",
    "countryCode": 501,
    "culture": "int"
}, {
    "countryId": 22,
    "country": "Benin",
    "iso": "BJ",
    "countryCode": 229,
    "culture": "int"
}, {
    "countryId": 23,
    "country": "Bermuda",
    "iso": "BM",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 24,
    "country": "Bhutan",
    "iso": "BT",
    "countryCode": 975,
    "culture": "int"
}, {
    "countryId": 25,
    "country": "Bolivia",
    "iso": "BO",
    "countryCode": 591,
    "culture": "int"
}, {
    "countryId": 26,
    "country": "Bosnia and Herzegovina",
    "iso": "BA",
    "countryCode": 387,
    "culture": "int"
}, {
    "countryId": 27,
    "country": "Botswana",
    "iso": "BW",
    "countryCode": 267,
    "culture": "int"
}, {
    "countryId": 29,
    "country": "Brazil",
    "iso": "BR",
    "countryCode": 55,
    "culture": "int"
}, {
    "countryId": 30,
    "country": "British Indian Ocean Territory",
    "iso": "IO",
    "countryCode": 246,
    "culture": "int"
}, {
    "countryId": 31,
    "country": "Brunei Darussalam",
    "iso": "BN",
    "countryCode": 673,
    "culture": "int"
}, {
    "countryId": 32,
    "country": "Bulgaria",
    "iso": "BG",
    "countryCode": 359,
    "culture": "eu"
}, {
    "countryId": 33,
    "country": "Burkina Faso",
    "iso": "BF",
    "countryCode": 226,
    "culture": "int"
}, {
    "countryId": 35,
    "country": "Burundi",
    "iso": "BI",
    "countryCode": 257,
    "culture": "int"
}, {
    "countryId": 36,
    "country": "Cambodia",
    "iso": "KH",
    "countryCode": 855,
    "culture": "int"
}, {
    "countryId": 37,
    "country": "Cameroon",
    "iso": "CM",
    "countryCode": 237,
    "culture": "int"
}, {
    "countryId": 38,
    "country": "Canada",
    "iso": "CA",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 39,
    "country": "Cape Verde",
    "iso": "CV",
    "countryCode": 238,
    "culture": "int"
}, {
    "countryId": 40,
    "country": "Cayman Islands",
    "iso": "KY",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 41,
    "country": "Central African Republic",
    "iso": "CF",
    "countryCode": 236,
    "culture": "syc"
}, {
    "countryId": 42,
    "country": "Chad",
    "iso": "TD",
    "countryCode": 235,
    "culture": "int"
}, {
    "countryId": 43,
    "country": "Chile",
    "iso": "CL",
    "countryCode": 56,
    "culture": "int"
}, {
    "countryId": 44,
    "country": "China",
    "iso": "CN",
    "countryCode": 86,
    "culture": "syc"
}, {
    "countryId": 45,
    "country": "Christmas Island",
    "iso": "CX",
    "countryCode": 61,
    "culture": "int"
}, {
    "countryId": 46,
    "country": "Cocos (Keeling) Islands",
    "iso": "CC",
    "countryCode": 61,
    "culture": "int"
}, {
    "countryId": 47,
    "country": "Colombia",
    "iso": "CO",
    "countryCode": 57,
    "culture": "int"
}, {
    "countryId": 48,
    "country": "Comoros",
    "iso": "KM",
    "countryCode": 269,
    "culture": "int"
}, {
    "countryId": 49,
    "country": "Congo",
    "iso": "CG",
    "countryCode": 242,
    "culture": "int"
}, {
    "countryId": 50,
    "country": "Congo, Democratic Republic of the",
    "iso": "CD",
    "countryCode": 243,
    "culture": "int"
}, {
    "countryId": 51,
    "country": "Cook Islands",
    "iso": "CK",
    "countryCode": 682,
    "culture": "int"
}, {
    "countryId": 52,
    "country": "Costa Rica",
    "iso": "CR",
    "countryCode": 506,
    "culture": "int"
}, {
    "countryId": 53,
    "country": "Cote d'Ivoire",
    "iso": "CI",
    "countryCode": 225,
    "culture": "int"
}, {
    "countryId": 54,
    "country": "Croatia",
    "iso": "HR",
    "countryCode": 385,
    "culture": "eu"
}, {
    "countryId": 250,
    "country": "Cuba",
    "iso": "CU",
    "countryCode": 53,
    "culture": "int"
}, {
    "countryId": 55,
    "country": "Cyprus",
    "iso": "CY",
    "countryCode": 357,
    "culture": "eu"
}, {
    "countryId": 56,
    "country": "Czech Republic",
    "iso": "CZ",
    "countryCode": 420,
    "culture": "eu"
}, {
    "countryId": 57,
    "country": "Denmark",
    "iso": "DK",
    "countryCode": 45,
    "culture": "eu"
}, {
    "countryId": 58,
    "country": "Djibouti",
    "iso": "DJ",
    "countryCode": 253,
    "culture": "int"
}, {
    "countryId": 59,
    "country": "Dominica",
    "iso": "DM",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 60,
    "country": "Dominican Republic",
    "iso": "DO",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 61,
    "country": "East Timor",
    "iso": "TL",
    "countryCode": 670,
    "culture": "int"
}, {
    "countryId": 62,
    "country": "Ecuador",
    "iso": "EC",
    "countryCode": 593,
    "culture": "int"
}, {
    "countryId": 63,
    "country": "Egypt",
    "iso": "EG",
    "countryCode": 20,
    "culture": "syc"
}, {
    "countryId": 64,
    "country": "El Salvador",
    "iso": "SV",
    "countryCode": 503,
    "culture": "int"
}, {
    "countryId": 66,
    "country": "Equatorial Guinea",
    "iso": "GQ",
    "countryCode": 240,
    "culture": "int"
}, {
    "countryId": 67,
    "country": "Eritrea",
    "iso": "ER",
    "countryCode": 291,
    "culture": "int"
}, {
    "countryId": 69,
    "country": "Estonia",
    "iso": "EE",
    "countryCode": 372,
    "culture": "eu"
}, {
    "countryId": 70,
    "country": "Ethiopia",
    "iso": "ET",
    "countryCode": 251,
    "culture": "int"
}, {
    "countryId": 71,
    "country": "Falkland Islands",
    "iso": "FK",
    "countryCode": 500,
    "culture": "int"
}, {
    "countryId": 72,
    "country": "Faroe Islands",
    "iso": "FO",
    "countryCode": 298,
    "culture": "int"
}, {
    "countryId": 73,
    "country": "Fiji",
    "iso": "FJ",
    "countryCode": 679,
    "culture": "int"
}, {
    "countryId": 74,
    "country": "Finland",
    "iso": "FI",
    "countryCode": 358,
    "culture": "eu"
}, {
    "countryId": 75,
    "country": "France",
    "iso": "FR",
    "countryCode": 33,
    "culture": "eu"
}, {
    "countryId": 76,
    "country": "French Guiana",
    "iso": "GF",
    "countryCode": 594,
    "culture": "eu"
}, {
    "countryId": 77,
    "country": "French Polynesia",
    "iso": "PF",
    "countryCode": 689,
    "culture": "int"
}, {
    "countryId": 79,
    "country": "Gabon",
    "iso": "GA",
    "countryCode": 241,
    "culture": "int"
}, {
    "countryId": 80,
    "country": "Gambia",
    "iso": "GM",
    "countryCode": 220,
    "culture": "int"
}, {
    "countryId": 81,
    "country": "Georgia",
    "iso": "GE",
    "countryCode": 995,
    "culture": "int"
}, {
    "countryId": 82,
    "country": "Germany",
    "iso": "DE",
    "countryCode": 49,
    "culture": "eu"
}, {
    "countryId": 83,
    "country": "Ghana",
    "iso": "GH",
    "countryCode": 233,
    "culture": "int"
}, {
    "countryId": 84,
    "country": "Gibraltar",
    "iso": "GI",
    "countryCode": 350,
    "culture": "int"
}, {
    "countryId": 86,
    "country": "Greece",
    "iso": "GR",
    "countryCode": 30,
    "culture": "eu"
}, {
    "countryId": 87,
    "country": "Greenland",
    "iso": "GL",
    "countryCode": 299,
    "culture": "int"
}, {
    "countryId": 88,
    "country": "Grenada",
    "iso": "GD",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 89,
    "country": "Guadeloupe",
    "iso": "GP",
    "countryCode": 590,
    "culture": "eu"
}, {
    "countryId": 91,
    "country": "Guatemala",
    "iso": "GT",
    "countryCode": 502,
    "culture": "int"
}, {
    "countryId": 252,
    "country": "Guernsey",
    "iso": "GG",
    "countryCode": 44,
    "culture": "int"
}, {
    "countryId": 92,
    "country": "Guinea",
    "iso": "GN",
    "countryCode": 224,
    "culture": "syc"
}, {
    "countryId": 93,
    "country": "Guinea-Bissau",
    "iso": "GW",
    "countryCode": 245,
    "culture": "int"
}, {
    "countryId": 94,
    "country": "Guyana",
    "iso": "GY",
    "countryCode": 592,
    "culture": "int"
}, {
    "countryId": 246,
    "country": "Haiti",
    "iso": "HT",
    "countryCode": 509,
    "culture": "int"
}, {
    "countryId": 96,
    "country": "Honduras",
    "iso": "HN",
    "countryCode": 504,
    "culture": "int"
}, {
    "countryId": 97,
    "country": "Hong Kong",
    "iso": "HK",
    "countryCode": 852,
    "culture": "int"
}, {
    "countryId": 98,
    "country": "Hungary",
    "iso": "HU",
    "countryCode": 36,
    "culture": "eu"
}, {
    "countryId": 99,
    "country": "Iceland",
    "iso": "IS",
    "countryCode": 354,
    "culture": "eu"
}, {
    "countryId": 100,
    "country": "India",
    "iso": "IN",
    "countryCode": 91,
    "culture": "int"
}, {
    "countryId": 101,
    "country": "Indonesia",
    "iso": "ID",
    "countryCode": 62,
    "culture": "int"
}, {
    "countryId": 244,
    "country": "Iran",
    "iso": "IR",
    "countryCode": 98,
    "culture": "int"
}, {
    "countryId": 248,
    "country": "Iraq",
    "iso": "IQ",
    "countryCode": 964,
    "culture": "int"
}, {
    "countryId": 102,
    "country": "Ireland",
    "iso": "IE",
    "countryCode": 353,
    "culture": "eu"
}, {
    "countryId": 254,
    "country": "Isle of Man",
    "iso": "IM",
    "countryCode": 44,
    "culture": "int"
}, {
    "countryId": 104,
    "country": "Italy",
    "iso": "IT",
    "countryCode": 39,
    "culture": "eu"
}, {
    "countryId": 105,
    "country": "Jamaica",
    "iso": "JM",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 106,
    "country": "Japan",
    "iso": "JP",
    "countryCode": 81,
    "culture": "int"
}, {
    "countryId": 255,
    "country": "Jersey",
    "iso": "JE",
    "countryCode": 44,
    "culture": "int"
}, {
    "countryId": 107,
    "country": "Jordan",
    "iso": "JO",
    "countryCode": 962,
    "culture": "int"
}, {
    "countryId": 108,
    "country": "Kazakhstan",
    "iso": "KZ",
    "countryCode": 7,
    "culture": "int"
}, {
    "countryId": 109,
    "country": "Kenya",
    "iso": "KE",
    "countryCode": 254,
    "culture": "int"
}, {
    "countryId": 110,
    "country": "Kiribati",
    "iso": "KI",
    "countryCode": 686,
    "culture": "int"
}, {
    "countryId": 114,
    "country": "Kuwait",
    "iso": "KW",
    "countryCode": 965,
    "culture": "int"
}, {
    "countryId": 115,
    "country": "Kyrgyzstan",
    "iso": "KG",
    "countryCode": 996,
    "culture": "int"
}, {
    "countryId": 116,
    "country": "Lao People's Democratic Republic",
    "iso": "LA",
    "countryCode": 856,
    "culture": "int"
}, {
    "countryId": 117,
    "country": "Latvia",
    "iso": "LV",
    "countryCode": 371,
    "culture": "eu"
}, {
    "countryId": 118,
    "country": "Lebanon",
    "iso": "LB",
    "countryCode": 961,
    "culture": "syc"
}, {
    "countryId": 119,
    "country": "Lesotho",
    "iso": "LS",
    "countryCode": 266,
    "culture": "int"
}, {
    "countryId": 120,
    "country": "Liberia",
    "iso": "LR",
    "countryCode": 231,
    "culture": "int"
}, {
    "countryId": 256,
    "country": "Libyan Arab Jamahiriya",
    "iso": "LY",
    "countryCode": 218,
    "culture": "int"
}, {
    "countryId": 121,
    "country": "Liechtenstein",
    "iso": "LI",
    "countryCode": 423,
    "culture": "eu"
}, {
    "countryId": 122,
    "country": "Lithuania",
    "iso": "LT",
    "countryCode": 370,
    "culture": "eu"
}, {
    "countryId": 123,
    "country": "Luxembourg",
    "iso": "LU",
    "countryCode": 352,
    "culture": "eu"
}, {
    "countryId": 124,
    "country": "Macau",
    "iso": "MO",
    "countryCode": 853,
    "culture": "int"
}, {
    "countryId": 126,
    "country": "Madagascar",
    "iso": "MG",
    "countryCode": 261,
    "culture": "int"
}, {
    "countryId": 127,
    "country": "Malawi",
    "iso": "MW",
    "countryCode": 265,
    "culture": "int"
}, {
    "countryId": 128,
    "country": "Malaysia",
    "iso": "MY",
    "countryCode": 60,
    "culture": "int"
}, {
    "countryId": 129,
    "country": "Maldives",
    "iso": "MV",
    "countryCode": 960,
    "culture": "int"
}, {
    "countryId": 130,
    "country": "Mali",
    "iso": "ML",
    "countryCode": 223,
    "culture": "syc"
}, {
    "countryId": 131,
    "country": "Malta",
    "iso": "MT",
    "countryCode": 356,
    "culture": "eu"
}, {
    "countryId": 132,
    "country": "Marshall Islands",
    "iso": "MH",
    "countryCode": 692,
    "culture": "int"
}, {
    "countryId": 133,
    "country": "Martinique",
    "iso": "MQ",
    "countryCode": 596,
    "culture": "eu"
}, {
    "countryId": 134,
    "country": "Mauritania",
    "iso": "MR",
    "countryCode": 222,
    "culture": "int"
}, {
    "countryId": 135,
    "country": "Mauritius",
    "iso": "MU",
    "countryCode": 230,
    "culture": "int"
}, {
    "countryId": 136,
    "country": "Mayotte",
    "iso": "YT",
    "countryCode": 262,
    "culture": "int"
}, {
    "countryId": 137,
    "country": "Mexico",
    "iso": "MX",
    "countryCode": 52,
    "culture": "int"
}, {
    "countryId": 138,
    "country": "Micronesia, Federated States of",
    "iso": "FM",
    "countryCode": 691,
    "culture": "int"
}, {
    "countryId": 139,
    "country": "Moldova, Republic of",
    "iso": "MD",
    "countryCode": 373,
    "culture": "int"
}, {
    "countryId": 140,
    "country": "Monaco",
    "iso": "MC",
    "countryCode": 377,
    "culture": "int"
}, {
    "countryId": 141,
    "country": "Mongolia",
    "iso": "MN",
    "countryCode": 976,
    "culture": "int"
}, {
    "countryId": 262,
    "country": "Montenegro",
    "iso": "ME",
    "countryCode": 382,
    "culture": "int"
}, {
    "countryId": 142,
    "country": "Montserrat",
    "iso": "MS",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 143,
    "country": "Morocco",
    "iso": "MA",
    "countryCode": 212,
    "culture": "int"
}, {
    "countryId": 144,
    "country": "Mozambique",
    "iso": "MZ",
    "countryCode": 258,
    "culture": "int"
}, {
    "countryId": 145,
    "country": "Myanmar",
    "iso": "MM",
    "countryCode": 95,
    "culture": "syc"
}, {
    "countryId": 146,
    "country": "Namibia",
    "iso": "NA",
    "countryCode": 264,
    "culture": "int"
}, {
    "countryId": 147,
    "country": "Nauru",
    "iso": "NR",
    "countryCode": 674,
    "culture": "int"
}, {
    "countryId": 148,
    "country": "Nepal",
    "iso": "NP",
    "countryCode": 977,
    "culture": "int"
}, {
    "countryId": 149,
    "country": "Netherlands",
    "iso": "NL",
    "countryCode": 31,
    "culture": "eu"
}, {
    "countryId": 150,
    "country": "Netherlands Antilles",
    "iso": "AN",
    "countryCode": 599,
    "culture": "int"
}, {
    "countryId": 151,
    "country": "New Caledonia",
    "iso": "NC",
    "countryCode": 687,
    "culture": "int"
}, {
    "countryId": 152,
    "country": "New Zealand",
    "iso": "NZ",
    "countryCode": 64,
    "culture": "int"
}, {
    "countryId": 153,
    "country": "Nicaragua",
    "iso": "NI",
    "countryCode": 505,
    "culture": "syc"
}, {
    "countryId": 154,
    "country": "Niger",
    "iso": "NE",
    "countryCode": 227,
    "culture": "int"
}, {
    "countryId": 155,
    "country": "Nigeria",
    "iso": "NG",
    "countryCode": 234,
    "culture": "int"
}, {
    "countryId": 156,
    "country": "Niue",
    "iso": "NU",
    "countryCode": 683,
    "culture": "int"
}, {
    "countryId": 157,
    "country": "Norfolk Island",
    "iso": "NF",
    "countryCode": 672,
    "culture": "int"
}, {
    "countryId": 125,
    "country": "North Macedonia",
    "iso": "MK",
    "countryCode": 389,
    "culture": "int"
}, {
    "countryId": 159,
    "country": "Northern Mariana Islands",
    "iso": "MP",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 160,
    "country": "Norway",
    "iso": "NO",
    "countryCode": 47,
    "culture": "eu"
}, {
    "countryId": 161,
    "country": "Oman",
    "iso": "OM",
    "countryCode": 968,
    "culture": "int"
}, {
    "countryId": 162,
    "country": "Pakistan",
    "iso": "PK",
    "countryCode": 92,
    "culture": "int"
}, {
    "countryId": 163,
    "country": "Palau",
    "iso": "PW",
    "countryCode": 680,
    "culture": "int"
}, {
    "countryId": 242,
    "country": "Palestine",
    "iso": "PS",
    "countryCode": 970,
    "culture": "int"
}, {
    "countryId": 164,
    "country": "Panama",
    "iso": "PA",
    "countryCode": 507,
    "culture": "int"
}, {
    "countryId": 165,
    "country": "Papua New Guinea",
    "iso": "PG",
    "countryCode": 675,
    "culture": "int"
}, {
    "countryId": 166,
    "country": "Paraguay",
    "iso": "PY",
    "countryCode": 595,
    "culture": "int"
}, {
    "countryId": 167,
    "country": "Peru",
    "iso": "PE",
    "countryCode": 51,
    "culture": "int"
}, {
    "countryId": 168,
    "country": "Philippines",
    "iso": "PH",
    "countryCode": 63,
    "culture": "int"
}, {
    "countryId": 169,
    "country": "Pitcairn",
    "iso": "PN",
    "countryCode": 64,
    "culture": "int"
}, {
    "countryId": 170,
    "country": "Poland",
    "iso": "PL",
    "countryCode": 48,
    "culture": "eu"
}, {
    "countryId": 171,
    "country": "Portugal",
    "iso": "PT",
    "countryCode": 351,
    "culture": "eu"
}, {
    "countryId": 173,
    "country": "Qatar",
    "iso": "QA",
    "countryCode": 974,
    "culture": "int"
}, {
    "countryId": 174,
    "country": "Reunion",
    "iso": "RE",
    "countryCode": 262,
    "culture": "eu"
}, {
    "countryId": 175,
    "country": "Romania",
    "iso": "RO",
    "countryCode": 40,
    "culture": "eu"
}, {
    "countryId": 177,
    "country": "Russian Federation",
    "iso": "RU",
    "countryCode": 7,
    "culture": "syc"
}, {
    "countryId": 178,
    "country": "Rwanda",
    "iso": "RW",
    "countryCode": 250,
    "culture": "int"
}, {
    "countryId": 257,
    "country": "Saint Helena",
    "iso": "SH",
    "countryCode": 290,
    "culture": "int"
}, {
    "countryId": 179,
    "country": "Saint Kitts and Nevis",
    "iso": "KN",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 180,
    "country": "Saint Lucia",
    "iso": "LC",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 265,
    "country": "Saint Martin",
    "iso": "MF",
    "countryCode": 590,
    "culture": "eu"
}, {
    "countryId": 201,
    "country": "Saint Pierre and Miquelon",
    "iso": "PM",
    "countryCode": 508,
    "culture": "int"
}, {
    "countryId": 181,
    "country": "Saint Vincent and the Grenadines",
    "iso": "VC",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 182,
    "country": "Samoa, Independent State of",
    "iso": "WS",
    "countryCode": 685,
    "culture": "int"
}, {
    "countryId": 183,
    "country": "San Marino",
    "iso": "SM",
    "countryCode": 378,
    "culture": "int"
}, {
    "countryId": 184,
    "country": "Sao Tome and Principe",
    "iso": "ST",
    "countryCode": 239,
    "culture": "int"
}, {
    "countryId": 185,
    "country": "Saudi Arabia",
    "iso": "SA",
    "countryCode": 966,
    "culture": "int"
}, {
    "countryId": 187,
    "country": "Senegal",
    "iso": "SN",
    "countryCode": 221,
    "culture": "int"
}, {
    "countryId": 243,
    "country": "Serbia",
    "iso": "RS",
    "countryCode": 381,
    "culture": "int"
}, {
    "countryId": 188,
    "country": "Seychelles",
    "iso": "SC",
    "countryCode": 248,
    "culture": "int"
}, {
    "countryId": 189,
    "country": "Sierra Leone",
    "iso": "SL",
    "countryCode": 232,
    "culture": "int"
}, {
    "countryId": 190,
    "country": "Singapore",
    "iso": "SG",
    "countryCode": 65,
    "culture": "au"
}, {
    "countryId": 191,
    "country": "Slovakia",
    "iso": "SK",
    "countryCode": 421,
    "culture": "eu"
}, {
    "countryId": 192,
    "country": "Slovenia",
    "iso": "SI",
    "countryCode": 386,
    "culture": "eu"
}, {
    "countryId": 193,
    "country": "Solomon Islands",
    "iso": "SB",
    "countryCode": 677,
    "culture": "int"
}, {
    "countryId": 194,
    "country": "Somalia",
    "iso": "SO",
    "countryCode": 252,
    "culture": "int"
}, {
    "countryId": 195,
    "country": "South Africa",
    "iso": "ZA",
    "countryCode": 27,
    "culture": "int"
}, {
    "countryId": 196,
    "country": "South Georgia and the South Sandwich Islands",
    "iso": "GS",
    "countryCode": 500,
    "culture": "int"
}, {
    "countryId": 112,
    "country": "South Korea (Republic of Korea)",
    "iso": "KR",
    "countryCode": 82,
    "culture": "int"
}, {
    "countryId": 198,
    "country": "Spain",
    "iso": "ES",
    "countryCode": 34,
    "culture": "eu"
}, {
    "countryId": 199,
    "country": "Sri Lanka",
    "iso": "LK",
    "countryCode": 94,
    "culture": "int"
}, {
    "countryId": 245,
    "country": "Sudan",
    "iso": "SD",
    "countryCode": 249,
    "culture": "int"
}, {
    "countryId": 202,
    "country": "Suriname",
    "iso": "SR",
    "countryCode": 597,
    "culture": "int"
}, {
    "countryId": 203,
    "country": "Svalbard and Jan Mayen Islands",
    "iso": "SJ",
    "countryCode": 47,
    "culture": "int"
}, {
    "countryId": 204,
    "country": "Swaziland",
    "iso": "SZ",
    "countryCode": 268,
    "culture": "int"
}, {
    "countryId": 205,
    "country": "Sweden",
    "iso": "SE",
    "countryCode": 46,
    "culture": "eu"
}, {
    "countryId": 206,
    "country": "Switzerland",
    "iso": "CH",
    "countryCode": 41,
    "culture": "int"
}, {
    "countryId": 247,
    "country": "Syria",
    "iso": "SY",
    "countryCode": 963,
    "culture": "int"
}, {
    "countryId": 207,
    "country": "Taiwan",
    "iso": "TW",
    "countryCode": 886,
    "culture": "int"
}, {
    "countryId": 208,
    "country": "Tajikistan",
    "iso": "TJ",
    "countryCode": 992,
    "culture": "int"
}, {
    "countryId": 209,
    "country": "Tanzania",
    "iso": "TZ",
    "countryCode": 255,
    "culture": "int"
}, {
    "countryId": 210,
    "country": "Thailand",
    "iso": "TH",
    "countryCode": 66,
    "culture": "int"
}, {
    "countryId": 211,
    "country": "Togo",
    "iso": "TG",
    "countryCode": 228,
    "culture": "int"
}, {
    "countryId": 212,
    "country": "Tokelau",
    "iso": "TK",
    "countryCode": 690,
    "culture": "int"
}, {
    "countryId": 213,
    "country": "Tonga",
    "iso": "TO",
    "countryCode": 676,
    "culture": "int"
}, {
    "countryId": 215,
    "country": "Trinidad and Tobago",
    "iso": "TT",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 216,
    "country": "Tunisia",
    "iso": "TN",
    "countryCode": 216,
    "culture": "syc"
}, {
    "countryId": 217,
    "country": "Turkey",
    "iso": "TR",
    "countryCode": 90,
    "culture": "int"
}, {
    "countryId": 218,
    "country": "Turkmenistan",
    "iso": "TM",
    "countryCode": 993,
    "culture": "int"
}, {
    "countryId": 219,
    "country": "Turks and Caicos Islands",
    "iso": "TC",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 220,
    "country": "Tuvalu",
    "iso": "TV",
    "countryCode": 688,
    "culture": "int"
}, {
    "countryId": 221,
    "country": "Uganda",
    "iso": "UG",
    "countryCode": 256,
    "culture": "int"
}, {
    "countryId": 222,
    "country": "Ukraine",
    "iso": "UA",
    "countryCode": 380,
    "culture": "syc"
}, {
    "countryId": 223,
    "country": "United Arab Emirates",
    "iso": "AE",
    "countryCode": 971,
    "culture": "int"
}, {
    "countryId": 264,
    "country": "United Kingdom",
    "iso": "GB",
    "countryCode": 44,
    "culture": "syc"
}, {
    "countryId": 227,
    "country": "Uruguay",
    "iso": "UY",
    "countryCode": 598,
    "culture": "int"
}, {
    "countryId": 229,
    "country": "Uzbekistan",
    "iso": "UZ",
    "countryCode": 998,
    "culture": "int"
}, {
    "countryId": 230,
    "country": "Vanuatu",
    "iso": "VU",
    "countryCode": 678,
    "culture": "int"
}, {
    "countryId": 231,
    "country": "Vatican City State (Holy See)",
    "iso": "VA",
    "countryCode": 39,
    "culture": "int"
}, {
    "countryId": 232,
    "country": "Venezuela",
    "iso": "VE",
    "countryCode": 58,
    "culture": "syc"
}, {
    "countryId": 233,
    "country": "Vietnam",
    "iso": "VN",
    "countryCode": 84,
    "culture": "int"
}, {
    "countryId": 234,
    "country": "Virgin Islands (British)",
    "iso": "VG",
    "countryCode": 1,
    "culture": "int"
}, {
    "countryId": 237,
    "country": "Wallis and Futuna Islands",
    "iso": "WF",
    "countryCode": 681,
    "culture": "int"
}, {
    "countryId": 238,
    "country": "Western Sahara",
    "iso": "EH",
    "countryCode": 212,
    "culture": "int"
}, {
    "countryId": 239,
    "country": "Yemen",
    "iso": "YE",
    "countryCode": 967,
    "culture": "int"
}, {
    "countryId": 240,
    "country": "Zambia",
    "iso": "ZM",
    "countryCode": 260,
    "culture": "int"
}, {
    "countryId": 241,
    "country": "Zimbabwe",
    "iso": "ZW",
    "countryCode": 263,
    "culture": "syc"
}]

export default countries;