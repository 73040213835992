import Apis from "../apis/Apis";

import enums from "../configs/enums";
import widgets from "../configs/widgets";
import countries from "../configs/countries";
import reactStringReplace from 'react-string-replace';

import getCultureId from "../configs/cultureIds";

const utils = {
    /**
     * This function will return the array of options for select box with keys and values
     * 
     * @param {String} name - this is the key of object which will be used to create options from 
     * @returns array of <options>
     */
    getOptions: (name) => {
        let data = utils.getEnvDataFromEnums(name);

        if (!data) {
            return <option key='undefined' value='undefined'>Undefined</option>;
        }

        return Object.keys(data).map((item) => {
            return <option key={data[item].key} value={data[item].key}>{data[item].value}</option>;
        });
    },
    /**
     * Use this method to get filtred data from enums based on environment
     * For example it will filter extra domains which we should not show in the prp/prod
     * 
     * @param {*} name - name of the object from enums
     * @return {Object} [name]: { key, value, ... } 
     */
    getEnvDataFromEnums: (name) => {
        let data;

        if (name === 'widgets') {
            data = widgets;
        }
        else if (name === 'domains') {
            // Just filter object keys where we don't have our environment in list
            data = Object.keys(enums[name])
                .filter(key => enums[name][key].environments.includes(process.env.REACT_APP_ENVIRONMENT))
                .reduce((cur, key) => Object.assign(cur, { [key]: enums[name][key] }), {});
        } else {
            data = enums[name];
        }

        return data;
    },
    /**
     * Copy text to clipboard
     * 
     * @param {*} text - value will be copied to clipboard 
     * @returns {Promise}
     */
    copyText: (text) => {
        return navigator.clipboard.writeText(text);
    },

    /**
     * This function is based on rules attribute, you should add it to target element before call this.
     * 
     * @param {*} target - used to specify control target which will be checked
     * @returns false if not valid, true if no issues 
     */
    checkRulesValidity: (target) => {

        if (!target?.attributes?.rules?.value) {
            console.debug("No rules found in target for checkRulesValidity. Skipping validation.");
            return null;
        }

        let rules = target.attributes.rules.value.split(',');
        let value = target.value.trim();

        let message = "";
        let validation_errors = [];

        for (const rule of rules) {
            let isValid = false;

            switch (rule) {
                case 'required':
                    message = 'validation_required_message';

                    // no need to handle other rules, as we don't have value
                    if (!value) {
                        return {
                            isValid: false,
                            message
                        };
                    }

                    isValid = !!value;
                    break;
                case 'name':
                    // Support most of unicode characters (for Polish, Bulgarian, etc)
                    isValid = /^[ \p{L}]*[\p{L}]$/u.test(value);
                    message = 'validation_name_expr_message';
                    break;
                case 'email':
                    isValid = !!String(value)
                        .toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                    message = 'validation_invalid_email_message';
                    break;
                case 'password':
                    isValid = /^.{6,20}$/.test(value);
                    message = 'validation_password_length_message';
                    break;
                case 'phone':
                    // only digits 
                    if (!/^[0-9]*$/.test(value)) {
                        message = 'validation_phone_expr_message';
                        break;
                    }
                    //  5-15 length
                    if (!/^.{5,15}$/.test(value)) {
                        message = 'validation_phone_length_message';
                        break;
                    }

                    isValid = true;
                    break;
                case 'phone_code':
                    isValid = /^[0-9]{1,4}$/.test(value);
                    message = "validation_phone_code_expr_message";
                    break;
                default:
                    console.warn("Not existing rule for falidation: ", rule);
                    break;
            }

            if (!isValid) validation_errors.push(isValid);
        }

        if (validation_errors.length)
            return {isValid: false, message};
        else
            return {isValid: true};
    },
    checkExistUserName: (value) => {
        return Apis.CheckExistUserName(value);
    },
    /**
     * Use this function to remove all validation status classes from target and his childs
     * 
     * @param {element} target 
     */
    clearValidationStatusClasses: (target) => {
        Array.from(target.querySelectorAll('.is-valid')).forEach(element => element.classList.remove('is-valid'));
        Array.from(target.querySelectorAll('.is-invalid')).forEach(element => element.classList.remove('is-invalid'));
    },
    /**
     * Promise to form field validation
     * 
     * Can be used to create map of promises to check all form fields at once
     * Implementation can be finded in RegisterForm.js
     * 
     * This allow us to validate email and other fields with api call inside validation
     * 
     * @param {Element} element - form element to check 
     * @returns {Promise}
     */
    asyncCheckRulesValidity: (element) => {
        return new Promise(resolve => {
            resolve(
                utils.checkRulesValidity(element)
            );
        });
    },

    /**
     * Use this function to put a JSX value inside string.
     * It's like native javascript .replace() but available to put JSX strings.
     * https://github.com/iansinnott/react-string-replace
     * 
     * @param {String} string - initial text
     * @param {String} find - conditional string which will be replaced
     * @param {JSX} replace - string to replace (Can be string or JSX)
     * @returns 
     */
    reactStringReplace: (string, find, replace) => {
        let stringJSX = reactStringReplace(string, find, () => (
            replace
        ));
        return stringJSX;
    },
    /**
     * Returns an Object with 'culture' and 'cultureId'
     * 
     * Is this correct? Is the culture based on geoIp or country selection?
     * 
     * @param {*} countryId 
     * @param {String} language - should be in backend format (you can find them in enums.langs -> backendMap)
     * @returns {Object}
     */
    getCultureByCountryId: (countryId, language = 'en') => {
        let culture = countries.filter(country => parseInt(country.countryId) === parseInt(countryId))[0]?.culture;

        return {
            culture,
            cultureId: getCultureId(culture, language)
        };
    },
    /**
     * Returns the country object by countryId
     * 
     * @param {*} countryId 
     * @returns {Object}} 
     */
    getCountryByCountryId: (countryId) => {
        return countries.filter(country => parseInt(country.countryId) === parseInt(countryId))[0];
    },

    /**
     * This function is allow you to send data to frame parent window.
     * We filter reveived data by name on target page refer to easymarkets-tpw.js
     * 
     * A lot of security restrictions create things like this. Since we cannot access content of iframe and want to fit content properly
     * 
     * @param {Object} data
     */
    sendMessageToFrameParent: (data) => {
        console.log(JSON.stringify(data));
        window.parent.postMessage(JSON.stringify(data), '*');
    },

    /**
     * This function reveiving string and returning either:
     * 
     *  true - if provided string is JSON
     *  false - if error occurs while JSON.parse()
     * 
     * @param {String} str - string to check 
     * @returns 
     */
    isJsonObject: (str) => {
		try {
			const obj = JSON.parse(str);
			return typeof obj === 'object' && obj !== null;
		} catch (e) {
			return false;
		}
	},
    createSessionFromResponse: (response) => {
        const cookieAuth = {
            sessionId: '',
            isAuthenticated: false,
        };
        if (response && response.sessionId) {
            cookieAuth.sessionId = response.sessionId;
            cookieAuth.isAuthenticated = true;
		}

		return cookieAuth;
    }
};

export default utils;