import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import utils from "../../utils/utils";

import { registerForm } from "../../configs/formControls";

import { Row, Col, Button, Form } from 'react-bootstrap';

const CompleteEmail = ({value, onChange, submitText, onSubmit, disableSubmit, setDisableSubmit}) => {
    const { t } = useTranslation();

	const [emailAlreadyExistsValidation, setEmailAlreadyExistsValidation] = useState(false);

    /**
     * @todo: add error boundary here
     */
    const emailFormControlData = registerForm.long.filter((control) => control.key === 'email')[0];

    useEffect(()=>{
        setDisableSubmit(true)
    }, [])

    const handleChange = async(event) => {
        onChange(event)
        let emailControlEl = document.getElementById('completeEmailControl');

        await utils.checkRulesValidity(emailControlEl);

        let isInvalid = emailControlEl.classList.contains('is-invalid');
        let isInvalidWithMsg = emailControlEl.classList.contains('show-invalid-message');

        setEmailAlreadyExistsValidation(isInvalidWithMsg);
        setDisableSubmit(isInvalid || isInvalidWithMsg);
    }
    const handleSubmit = async(event) => {
        let emailControlEl = document.getElementById('completeEmailControl');

        if (emailControlEl.classList.contains('is-invalid')) return;
        if (emailControlEl.classList.contains('show-invalid-message')) return;
            
        onSubmit(event)
    }

    return (
        <div className="mx-auto">
            <Row>
                <Col>
                    <Form.Control
                        id="completeEmailControl"
                        className="register-form-control"
                        rules={emailFormControlData.rules}
                        type={emailFormControlData.type}
                        placeholder={t(emailFormControlData.placeholder)}
                        autoComplete={emailFormControlData.autocomplete}
                        value={value}
                        onChange={handleChange}
                    />
                    {emailAlreadyExistsValidation ?
                        <><small className="form-message-invalid d-inline-block mb-2">{t(emailFormControlData.userExistsMessage)}</small></> 
                        : null
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid">
                        <Button className="button btn-primary" name="button_register_social" onClick={handleSubmit} disabled={disableSubmit}>
                            {submitText}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default CompleteEmail;