/**
 * Culture Ids
 *
 * Mapping of cultures / languages supported in the
 * backend of easyMarkets.
 * 
 * By this id, backoffice can handle email formats and languages
 * 
 * copied from EM project app/Model/EasyMarkets.php
 **/
const cultureIds = {
    'int': {
        'en': 2,
        'de': 22,
        'es': 41,
        'fr': 43,
        'pl': 47,
        'gr': 46,
        'ru': 38,
        'ar': 13,
        'cn': 14,
        'vn': 34,
        'it': 59,
        'id': 62,
        'ja': 63,
        'pt': 79,
    },
    'syc': {
        'en': 66,
        'de': 69,
        'es': 72,
        'fr': 73,
        'pl': 75,
        'gr': 74,
        'ru': 71,
        'ar': 67,
        'cn': 68,
        'vn': 70,
        'it': 76,
        'id': 77,
        'ja': 78,
        'pt': 82,
    },
    'eu': {
        'en': 24,
        'de': 27,
        'es': 30,
        'fr': 29,
        'pl': 26,
        'gr': 25,
        'ru': 45,
        'ar': 40,
        'cn': 39,
        'vn': 50,
        'it': 57,
        'id': 60,
        'ja': 64,
        'pt': 80,
    },
    'au': {
        'en': 23,
        'de': 52,
        'es': 42,
        'fr': 53,
        'pl': 54,
        'gr': 55,
        'ru': 56,
        'ar': 44,
        'cn': 31,
        'vn': 51,
        'it': 58,
        'id': 61,
        'ja': 65,
        'pt': 81,
    }

};

const getCultureId = (culture, language) => {
    let cultureId = cultureIds[culture]?.[language];
    if (!cultureId) {
        cultureId = cultureIds['eu']['en'];
    }
    return cultureId;
}

export default getCultureId;