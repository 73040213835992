import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { renderToString } from 'react-dom/server';

import GlobalContext from "../../store/global-context";
import CultureRedirectLib from "../../libs/culture.redirect";
import LegalDocuments from "../../libs/legal.documents";



const CultureRedirect = (props) => {
    const { globalCtx } = useContext(GlobalContext);
    const [href, setHref] = useState('');
    const [termsConditionsHref, setTermsConditionsHref] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        let customerSupportHref = CultureRedirectLib.getCultureRedirectUrls(globalCtx.culture);
        async function fetchMyAPI() {
            let termsConditionsUrl = await LegalDocuments.getLegalDocumentUrls('client-agreement', globalCtx.language, globalCtx.culture);
            setTermsConditionsHref(termsConditionsUrl);
        }

        fetchMyAPI();

        setHref(customerSupportHref);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let cultureRedirectText = CultureRedirectLib.CultureRedirectContent(props.countryId, globalCtx.culture);
    const data = renderToString(cultureRedirectText);

    let text = data.replace(/%%SITE_URL%%/g, CultureRedirectLib.getSiteUrl(globalCtx.culture));
    text = text.replace(/%%COMPANY%%/g, CultureRedirectLib.getCompany(globalCtx.culture));
    text = text.replace(/%%CUSTOMER_SUPPORT_PAGE%%/g, `<a data-slug="contact-us" target="_blank" href=${href} title="${t('contact_our_customer_support_team')}">${t('customer_support_department')}</a>`);
    text = text.replace(/%%TERMS_CONDITIONS%%/g, `<a href="${termsConditionsHref}" data-legal-document="client-agreement" rel="noreferrer" target="_blank" >${t('terms_conditions')}</a>`);

    // some translations have html tags. In order to display the html markup, we need dangerouslySetInnerHTML
    // this is not really dangerous, as it comes from our code, and not user input
    return <p className="text-normal" dangerouslySetInnerHTML={{ __html: text }}></p>;
};

export default CultureRedirect;