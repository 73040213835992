/**
 * Simple script injection
 *
 * Takes script src, injects with async loading
 * and calls onload callback if supplied.
 *
 * @param {string} src Location of script to load.
 * @param {function} onload Callback function.
 * @return {void}
 */
const injectScript = (src, onload = null) => {

	if (!src || typeof src !== 'string') {
		console.error('Invalid source for script injection');
		return;
	}

	// If same script is already injected then return;
	if (document.querySelector(`script[src="${src}"]`)) {
		return;
	} 

	const firstScript = document.getElementsByTagName('script')[0];
	const newScript = document.createElement('script');

	// Set callback
	if (typeof onload === 'function') {
		newScript.onload = onload;
	}

	// Set async and src
	newScript.async = true;
	newScript.defer = true;
	newScript.src = src;

	// Inject script
	firstScript.parentNode.insertBefore(newScript, firstScript);
};

export default injectScript;
