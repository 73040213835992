const GetSession = async () => {

    let params = {
        method: "GET",
        api: 'GetSession',
        args: {
            applicationId: process.env.REACT_APP_EASYMARKETS_APP_ID
        }
    }

    let result = await Execute(params);

    if (!result) {
        return false;
    }

    let resultObject = JSON.parse(result);
    let sessionId = resultObject.results[0].GetSession.sessionId;

    return sessionId;

};



/**
   * core method to call Apis
   * @param {*} params: object with properties {api, method, args(not mandatory)}
   * @returns 
   */
const Execute = async (params) => {

    console.log(`Calling API:${params.api}, method:${params.method}`);

    let apiUrl = process.env.REACT_APP_EASYMARKETS_API_URL;
    let appId = process.env.REACT_APP_EASYMARKETS_APP_ID;

    let api = params.api;
    let requestOptions = {
        method: params.method,
    };

    let timestamp = Date.now();
    let args = JSON.stringify(params.args || {});

    // do not call this for GetSession api, to avoid infinite loop
    let sessionId = (api === 'GetSession') ? null : await GetSession();

    let url = `${apiUrl}/?rq=[{"action":"${api}","args":${args}}]&sid=${sessionId}&appid=${appId}&timestamp=${timestamp}`;

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        console.error(`Error in method:${params.method}, Status:${response.status}`);
        return false;
    }


    let result = await response.text();

    console.log(`Result: \n ${JSON.stringify(result)}`);

    return result;

};

export default Execute;

