import { useEffect } from 'react';

import injectScript from '../../utils/inject.script';

const Trustpilot = ({ lang }) => {
    // Locale per language
    const locales = {
        'eng': 'en-GB',
        'pol': 'pl-PL',
        'spa': 'es-ES',
    };

    // Set locale and tag
    const locale = locales[lang] || locales['eng'];

    useEffect(() => {
        // Inject trustpilot script and show elements
        injectScript('https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
    }, [])

    return (
        <div className="trustpilot-widget" data-locale={locale} data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="57ad73410000ff0005936364" data-style-height="20px" data-style-width="100%" data-theme="light">
            <a href="https://uk.trustpilot.com/review/easymarkets.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    );
}

export default Trustpilot;