/**
 * Gateway / Attribution functionality
 * 
 * Copied from EM project, same file name
 */
import DocCookies from './doc.cookies';
import Parse from './parse.lib';
import defaultValues from '../configs/defaultValues';

const Attribution = {

	setGatewayId: (id) => {
		if (id && !isNaN(id) & id != 0) {

			let date = new Date();		
			date.setDate(date.getDate() + defaultValues.gatewayCookieExpirationDays);

			DocCookies.setItem('de4fe6', 'TAR=' + id + '&JAR=0', date, null, 'easymarkets.com', 'None', true);
			DocCookies.setItem('gtw_id', id, date, null, 'easymarkets.com', 'None', true);

			return true;
		}

		return false;
	},

	getGatewayObject: () => {
		return Parse.gateway(DocCookies.getItem('de4fe6'));
	}
}

export default Attribution;