import { useState, useEffect, useRef, createElement } from "react";

import widgets from "../configs/widgets";
import defaultValues from "../configs/defaultValues";

import utils from "../utils/utils";

import SelectBox from './components/SelectBox';
import TextField from './components/TextField';
import CheckBox from './components/CheckBox';
import GenerateLink from "./components/GenerateLink";

import WidgetsModal from "../widgets/components/WidgetsModal";


export default function WidgetCreate() {

    const generateLinkModalRef = useRef();

    // You can use properties of this object to keep information about widget. This will not be passed into URL by default.
    const [widgetInfo, setWidgetInfo] = useState({
        domain: defaultValues.domain.key,
        widgetName: defaultValues.widgetName.key,
        affiliateName: '',
        employeeName: '',
        passcode: null
    });
    const [params, setParams] = useState({});
    const [options, setOptions] = useState({});
    const [generateLinkButtonDisabled, setGenerateLinkButtonDisabled] = useState(true);

    const handleWidgetInfoChange = (event, key) => setWidgetInfo({ ...widgetInfo, [key]: event.target.value });
    const handleParamChange = (event, key) => setParams({ ...params, [key]: event.target.value });
    const handleOptionCheck = (event, key) => setOptions({ ...options, [key]: event.target.checked });

    useEffect(() => {
        setGenerateLinkButtonDisabled(widgetInfo.passcode !== process.env.REACT_APP_PASSCODE);
    }, [widgetInfo.passcode]);

    // Update states on widgetName changed (also used to set initial state)
    useEffect(() => {
        let newParams = {};
        let newOptions = {};

        // We don't want put options in params so filter it out
        widgets[widgetInfo.widgetName].params.filter(param => ['SelectBox', 'TextField'].includes(param.type))
            // we use ternary operator for not overwrite state
            .forEach(param => newParams[param.key] = Object.keys(params).includes(param.key) ? params[param.key] : defaultValues.widgetParams[param.key]);
        setParams(newParams);

        widgets[widgetInfo.widgetName].params.filter(param => param.type === 'CheckBox')
            // we use ternary operator for not overwrite state. 
            // Here it's mandatory to avoid render issue when we have not conspicios defined default value for field with same key
            .forEach(option => newOptions[option.key] = Object.keys(options).includes(option.key) ? options[option.key] : option.defaultChecked ?? false);
        setOptions(newOptions);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetInfo.widgetName]);

    // form will be validated by default browser required 
    const generateLinkHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        generateLinkModalRef.current.toggleModal();
    };

    return (
        <div className="container">
            <form onSubmit={generateLinkHandler}>
                <SelectBox
                    label="Domain"
                    disableDefault
                    value={widgetInfo.domain ?? ''}
                    options={utils.getOptions('domains')}
                    onChange={event => handleWidgetInfoChange(event, 'domain')} />

                <SelectBox
                    label="Select Widget"
                    message="We'll never share your email with anyone else."
                    disableDefault
                    value={widgetInfo.widgetName ?? ''}
                    options={utils.getOptions('widgets')}
                    onChange={event => handleWidgetInfoChange(event, 'widgetName')} />

                <TextField 
                    label="Name of the affiliate"
                    value={widgetInfo.affiliateName ?? ''}
                    onChange={event => handleWidgetInfoChange(event, 'affiliateName')}
                    required />

                <TextField 
                    label="Name of easyMarkets employee"
                    autoComplete="username"
                    value={widgetInfo.employeeName ?? ''}
                    onChange={event => handleWidgetInfoChange(event, 'employeeName')}
                    required />
            
                <br />

                {widgets[widgetInfo.widgetName].params.map((param) => {
                    switch (param.type) {
                        case 'SelectBox':
                            return createElement(
                                SelectBox,
                                {
                                    ...param.props,
                                    key: param.key,
                                    value: params[param.key],
                                    options: utils.getOptions(param.getOptionsKey),
                                    onChange: event => handleParamChange(event, param.key)
                                },
                            );

                        case 'TextField':
                            return createElement(
                                TextField,
                                {
                                    ...param.props,
                                    key: param.key,
                                    value: params[param.key] ?? '',
                                    onChange: event => handleParamChange(event, param.key)
                                },
                            );

                        case 'CheckBox':
                            return createElement(
                                CheckBox,
                                {
                                    ...param.props,
                                    key: param.key,
                                    checked: params[param.key],
                                    defaultChecked: param.defaultChecked ?? false,
                                    onChange: event => handleOptionCheck(event, param.key)
                                },
                            );

                        default:
                            console.error("Something went wrong with generating params component");
                            return null;
                    }
                })}

                <hr />

                <TextField 
                    label="Passcode"
                    type="password"
                    autoComplete="password"
                    value={widgetInfo.passcode ?? ''}
                    onChange={event => handleWidgetInfoChange(event, 'passcode')}
                    required />

                {/* GENERATE LINK BUTTON */}
                <div className="container mt-5 d-flex">
                    <button disabled={generateLinkButtonDisabled} type="submit" className="btn btn-outline-secondary mx-auto">Generate link</button>
                </div>

                <WidgetsModal ref={generateLinkModalRef} dialogClassName="w-10" closeButton size="lg" title="Links & Preview" content={
                    <GenerateLink domain={widgetInfo.domain} widgetName={widgetInfo.widgetName} params={params} options={options} />
                } />
            </form>
        </div>
    );
}