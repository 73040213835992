/**
 * Put your component in order to create a sign up form
 * implementation can be found in src/widgets/register/RegisterForm.js
 * 
 * key: unique identifier for render and other purposes
 * type: identifier for render component type
 * rules: rules which will be applied to the component validation, for additional information check utils.checkRulesValidity()
 * autocomplete: same as html attribute
 */
export const registerForm = {
    long: [
        {
            key: 'first_name',
            name: 'first_name',
            type: 'text',
            placeholder: 'first_name',
            rules: ['required', 'name'],
            autoComplete: 'given-name',
        },
        {
            key: 'last_name',
            name: 'last_name',
            type: 'text',
            placeholder: 'last_name',
            rules: ['required', 'name'],
            autoComplete: 'family-name',
        },
        {
            key: 'email',
            name: 'email',
            type: 'email',
            placeholder: 'your_email',
            rules: ['email'],
            autoComplete: 'email',
        },
        {
            key: 'password',
            name: 'password',
            type: 'password',
            placeholder: 'create_password',
            rules: ['password'],
            autoComplete: 'current-password',
        },
        {
            key: 'phone',
            name: 'phone',
            type: 'tel',
            placeholder: 'your_phone_number',
            rules: ['phone'],
        },
        {
            key: 'country',
            name: 'country',
            type: 'select-country',
            placeholder: 'select_country',
            message: 'country_of_residence_help_message',
            rules: ['required'],
        },
        // @todo put back when requested
        // {
        //     key: 'culture_redirect',
        //     type: 'culture_redirect',
        // },
        {
            key: 'submit',
            type: 'btn-submit',
            text: 'sign_up'
        }
    ]
};

const formControls = {
    registerForm,
};

export default formControls;