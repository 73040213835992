import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";


import Apis from "../../apis/Apis";
import loginControls from "../../configs/loginControls";
import utils from "../../utils/utils";

import { Row, Col, Button, Form } from 'react-bootstrap';


export default function LoginForm(props) {
    const { t } = useTranslation();

    const [invalidFormFields, setInvalidFormFields] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [loginFormData, setLoginFormData] = useState({
        userName: '',
        password: '',
    });

    const handleLoginFormDataChange = (event, key) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setLoginFormData({ ...loginFormData, [key]: value });
    };

    const validateField = (field) => {
        let { isValid, message } = utils.checkRulesValidity(field);
        field.classList.toggle('is-valid', isValid);
		field.classList.toggle('is-invalid', !isValid);


		setInvalidFormFields((invalidFormFields) => {
			return {
				...invalidFormFields, 
				[field.name]: { isValid, message }
			};
		});
    };

    const controlsMap = {
        text: (control) => {
            return (
                <><Form.Control
                        {...control}
                        className={`login-form-control`}
                        placeholder={t(control.placeholder)}
                        value={loginFormData[control.key] ?? ''}
                        onChange={event => handleLoginFormDataChange(event, control.key)}
                        onBlur={(event) => validateField(event.target)}
                    />
                    {(invalidFormFields[control.key]?.message) ?
                        <><small className="form-message-invalid d-inline-block mb-2">{t(invalidFormFields[control.key]?.message)}</small></> : null}
                </>
            );
        },
        checkbox: (control) => {
            return (
                <Form.Check
                    {...control}
                    className={`login-form-control`}
                    checked={loginFormData[control.key] ?? false}
                    onChange={event => handleLoginFormDataChange(event, control.key)}
                    label={t(control.text)}
                />
            );
        },
        submit: (control) => {
            return (
                <div className="d-grid">
                    <Button {...control} className="button btn-primary" name="button_login" type="submit" disabled={disableSubmit} >
                        {t(control.text)}
                    </Button>
                </div>
            );
        }
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        event.stopPropagation();

        for (const element of event.target.elements) {
            // Pre-validation
            if (element.type === 'submit') continue;
            validateField(element);
        }

        if (Object.keys(invalidFormFields).filter(element => !invalidFormFields[element].isValid).length || !Object.keys(invalidFormFields).length) {
			console.debug(invalidFormFields);
            console.debug('Not all fields are valid, skipping submit');
			setDisableSubmit(false);
			return;
		}

        const response = await Apis.LoginToEasyMarkets(loginFormData);

        utils.sendMessageToFrameParent({
            auth: utils.createSessionFromResponse(response) 
        });
        
        setDisableSubmit(true);
    };

    return (
        <Form noValidate onSubmit={handleSubmit} {...props}>
            {loginControls.map((control) => {
                return (
                    <Row key={control.key}>
                        <Col>   
                            {controlsMap[control.type] ? controlsMap[control.type](control) : controlsMap.text(control)}
                        </Col>
                    </Row>
                );
            })}
        </Form>
    );
}