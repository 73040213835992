/**
 * Parsing functions
 */
class Parse {

	static url(url) {

		if (typeof url === 'string') {
			let anchor = document.createElement('a');
			anchor.href = url;
			return anchor;
		}

		return false;
	}

	static qs(query) {

		if (typeof query === 'object') return query;

		let qs = query || window.location.search || null;

		if (qs) {
			return (qs).replace(/(^\?)/,'').split('&').map(function(n){return n = n.split('='),this[n[0]] = decodeURIComponent(n[1]),this;}.bind({}))[0];
		}
		else {
			return {};
		}
	}

	static gateway(de4fe6) {

		const data = Parse.qs(de4fe6 || '');

		return ['TAR', 'SAR', 'LPR_Id', 'JAR'].reduce((acc, key) => {
			acc[key] = data[key] || '';
			return acc;
		}, {});
	}
}

/**
 * UnParsing functions
 */
export class UnParse {
	static qs(query) {

		if ('URLSearchParams' in window) {
			return new URLSearchParams(query).toString();
		}
		else {
			return Object.keys(query).reduce(function(a,k){a.push(k+'='+encodeURIComponent(query[k]));return a},[]).join('&');
		}
	}
}

export default Parse;
