import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import Attribution from "../../libs/attribution.lib";
import GlobalContext from "../../store/global-context";

import Apis from "../../apis/Apis";
import utils from '../../utils/utils';
import getErrorMessage from "../../configs/apiErrors";
import countries from "../../configs/countries";
import { registerForm } from '../../configs/formControls';

import { Row, Col, Button, Form } from 'react-bootstrap';
import WidgetsModal from "../components/WidgetsModal";
import CultureRedirect from "../components/CultureRedirect";

const RegisterForm = (props) => {
	const { globalCtx, setGlobalCtx } = useContext(GlobalContext);
	const { t } = useTranslation();

	const registrationCompleteModalRef = useRef();
	const [registrationCompleteModalMsg, setRegistrationCompleteModalMsg] = useState({});

	const [registerFormData, setRegisterFormData] = useState({});
	const [invalidFormFields, setInvalidFormFields] = useState({});
	const [disableSubmit, setDisableSubmit] = useState(false);

	// Synchronise form data with global state. 
	// e.g. if you change country of residence in Social Sign up Modal, here it will be synchronised with selection
	useEffect(() => {
		setRegisterFormData({
			...registerFormData,
			country: globalCtx.country,
			gatewayId: props.gatewayId || '',
		});
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalCtx.country]);

	const validateField = async (field) => {
		// RegExp field validation
		let { isValid, message } = utils.checkRulesValidity(field);

		// Email field validation with API
		if (isValid && field.name === 'email' && await Apis.CheckExistUserName(field.value)) {
			isValid = false;
			message = 'user_already_exists_message';
		}

		field.classList.toggle('is-valid', isValid);
		field.classList.toggle('is-invalid', !isValid);


		setInvalidFormFields((invalidFormFields) => {
			return {
				...invalidFormFields, 
				[field.name]: { isValid, message }
			};
		});

		// used to check if all fields are valid
		return isValid;
	};

	const handleRegisterFormDataChange = async (event, key) => {
		// Remove all non digit characters from input
		if (key === 'phone' || key === 'phone_code') {
			event.target.value = event.target.value.replace(/\D/g, '');
		}

		// Use newValue if you need to customize the value for registerFormData
		let newValue = event.target.value;
		if (key === 'country') {
			// Assemble country from countryId, since we receive only id from select box
			newValue = utils.getCountryByCountryId(newValue);
			const newCulture = utils.getCultureByCountryId(newValue.countryId, globalCtx.language);

			if (newCulture) {
				setGlobalCtx({
					...globalCtx,
					country: newValue,
					cultureId: newCulture.cultureId,
					culture: newCulture.culture
				});
			}
		}

		setRegisterFormData({ ...registerFormData, [key]: newValue });
	};

	// Fill various api parameters that do not come directly from user input
	const getApiInput = (initial) => {
		let input = {
			...initial,

			// country is chosen in an obvious way for user so take value from form, not from ctx, but actually it should be same
			countryId: initial.country.countryId,
			cultureId: globalCtx.cultureId,
			acceptTerms: globalCtx.acceptTerms,
			termsVersion: globalCtx.termsVersion
		};

		// Add gateway information
		let gateway = Attribution.getGatewayObject();
		input.gatewayId = gateway.TAR;
		input.gatewaySubId = gateway.SAR;
		input.gatewayLprId = gateway.LPR_Id;

		// Add UTM parameters
		input.utm_params = props.utmparams || {};

		return input;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		setDisableSubmit(true);

		// ceate temporary isValid variable to check if all fields are valid
		let isValid = true;
		for (const element of event.target.elements) {
			// Pre-validation
			if (element.type === 'submit') continue;

			const fieldIsValid = await validateField(element);
			if (isValid && !fieldIsValid) {
				isValid = false;
			}
		}

		// If there any invalid fields return
		if (!isValid) {
			setDisableSubmit(false);
			return;
		}

		let input = getApiInput(registerFormData);

		// Call CreateOrUpdateAccount API
		const response = await Apis.CreateOrUpdateAccount(input);
		if (!response.error) {
			setRegistrationCompleteModalMsg({
				title: t("thank_you_for_signing_up"),
				content: t("successful_signup_with_sent_email"),
				onVisibilityChange: () => {
					utils.sendMessageToFrameParent({
						userWasCreated: true
					});
				}
			});

			// Reset form
			setRegisterFormData({});
			// Reset validation helpers
			utils.clearValidationStatusClasses(event.target);

			utils.sendMessageToFrameParent({
				auth: utils.createSessionFromResponse(response),
				preventRedirect: true
			});

		} else {
			setRegistrationCompleteModalMsg({
				title: t("sign_up_error"),
				content: t(getErrorMessage(response.error)),
				onVisibilityChange: () => {}
			});
		}

		registrationCompleteModalRef.current.toggleModal();

		setDisableSubmit(false);
	};

	return (
		<>
			<Form noValidate onSubmit={handleSubmit} {...props}>
				{/* TODO: remove hardcoded 'long' propery to add viriability of forms. */}
				{registerForm.long.map((control) => {
					switch (control.type) {
						case 'tel':
							return (
								<Row className='form-row-tel' key={control.key}>
									<Col xs={3} sm={3}>
										<Form.Control
											{...control}
											className="register-form-control form-control-tel-code"
											name="phone_code"
											type={control.type}
											rules={['phone_code']}
											placeholder="+"
											autoComplete="tel-country-code"
											value={registerFormData[`${control.key}_code`] ?? globalCtx.country.countryCode ?? ''}
											onChange={event => handleRegisterFormDataChange(event, `${control.key}_code`)}
											onBlur={(event) => validateField(event.target)}
										/>
									</Col>
									<Col>
										<Form.Control
											{...control}
											className="register-form-control"
											name="phone"
											rules={control.rules}
											type={control.type}
											placeholder={t(control.placeholder)}
											autoComplete="tel-national"
											value={registerFormData[control.key] ?? ''}
											onChange={event => handleRegisterFormDataChange(event, control.key)}
											onBlur={(event) => validateField(event.target)}
										/>
									</Col>
										{(invalidFormFields[control.key]?.message) ?
											<><small className="form-message-invalid d-inline-block mb-2">{t(invalidFormFields[control.key]?.message)}</small></> : null}
								</Row>
							);
						case 'select-country':
							return (
								<Row key={control.key}>
									<Col>
										{/* @todo: move country selection to separate component to reuse in other places */}
										<Form.Select
											{...control}
											className="register-form-control"
											rules={control.rules}
											value={registerFormData[control.key]?.countryId ?? globalCtx.country.countryId ?? ''}
											onChange={event => handleRegisterFormDataChange(event, control.key)}
										>
											{countries.map(country => {
												return (
													<option value={country.countryId} key={country.countryId}>{t(country.country, { ns: 'countries' })}</option>
												);
											})}
										</Form.Select>
										<small className="help-block d-inline-block mb-2">{t(control.message)}</small>
									</Col>
								</Row>
							);
						case 'culture_redirect':
							return (
								<Row key={control.key}>
									<Col>
										<CultureRedirect {...control} countryId={registerFormData['country']} />
									</Col>
								</Row>
							);
						case 'btn-submit':
							return (
								<Row key={control.key}>
									<Col>
										<div className="d-grid">
											<Button {...control} className="button btn-primary" name="button_register" type="submit" disabled={disableSubmit} >
												{t(control.text)}
											</Button>
										</div>
									</Col>
								</Row>
							);
						default:
							return (
								<Row key={control.key}>
									<Col>
										<Form.Control
											{...control}
											className={`register-form-control`}
											placeholder={t(control.placeholder)}
											value={registerFormData[control.key] ?? ''}
											onChange={event => handleRegisterFormDataChange(event, control.key)}
											onBlur={(event) => validateField(event.target)}
											autoComplete="off"
										/>
										{(invalidFormFields[control.key]?.message) ?
											<><small className="form-message-invalid d-inline-block mb-2">{t(invalidFormFields[control.key]?.message)}</small></> : null}
									</Col>
								</Row>
							);
					}
				})}
			</Form>
			<WidgetsModal ref={registrationCompleteModalRef} closeButton title={registrationCompleteModalMsg.title} content={registrationCompleteModalMsg.content} onVisibilityChange={registrationCompleteModalMsg.onVisibilityChange} />
		</>
	);
};

export default RegisterForm;