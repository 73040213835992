import enums from "./enums";
import widgets from "./widgets";
import countries from "./countries";

const defaultValues = {
    domain: enums.domains[window.location.hostname] ?? enums.domains['connect.easymarkets.com'],
    widgetName: widgets['register'],
    widgetParams: {
        gateway: 0,
        language: enums.langs['eng'].key,
        culture: enums.cultures['eu'].key,
        layout: enums.layouts['vertical'].key,
        tester: enums.tester['2'].key
    },
    sponsorLogo: require('./../img/realmadrid-sponsor-eng.png'),
    // We don't have any preference about fallback country here, so just return first one
    fallbackLanguage: 'eng',
    fallbackCountry: countries[0],
    fallbackCulture: 'eu',
    fallbackCultureId: 2,
    geoIPCookieName: 'EMGEO2',
    geoIPCookieValue: '123.45.67.890:CY:Cyprus:eu:Asia/Nicosia',
    // Gateway valid for 30 days
    gatewayCookieExpirationDays: 30,
}

export default defaultValues;