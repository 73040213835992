import { locales } from "../configs/enums";

/**
 * This function will return locale in ISO format based on the current language
 * 
 * @example 'pol' => 'pl-PL'
 * @param {String} i18n_lang 
 * @returns {String} ISO locale
 */
export const getLocaleISOFromI18N = (i18n_lang) => {
    return locales[i18n_lang] || 'en-GB';
}