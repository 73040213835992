/**
 * Put your component in order to create a sign up form
 * implementation can be found in src/widgets/login/LoginForm.js
 * 
 * key: unique identifier for render and other purposes
 * type: identifier for render component type
 * rules: rules which will be applied to the component validation, for additional information check utils.checkRulesValidity()
 * autocomplete: same as html attribute
 */
const loginControls = [
    {
        key: 'userName',
        name: 'userName',
        type: 'email',
        placeholder: 'your_email_or_username',
        rules: ['required'],
        autoComplete: 'email',
    },
    {
        key: 'password',
        name: 'password',
        type: 'password',
        placeholder: 'create_password',
        rules: ['password'],
        autoComplete: 'current-password',
    },
    {
        key: 'submit',
        type: 'submit',
        text: 'Login'
    }
];


export default loginControls;