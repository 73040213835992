import { Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import HomePage from './pages/HomePage';
import WidgetCreate from './admin/WidgetCreate';
import NotFoundPage from './pages/NotFoundPage';
import TestApi from './tests/TestApi';
import Widgets from './widgets/Widgets';

const Router = () => {

    return (
        <>
            {!useLocation().pathname.includes('/widgets/') ? <NavBar /> : null}
            <div id="page-body">
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/admin" element={<WidgetCreate />} />
                    <Route path="/tests/testapi" element={<TestApi />} />
                    <Route path="/widgets/:widget" element={<Widgets />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </div>
        </>
    );
};

export default Router;
