import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import useSize from '@react-hook/size';

import GlobalContext from '../store/global-context';

import utils from '../utils/utils';
import cultureApi from '../configs/cultures';
import getCultureId from '../configs/cultureIds';
import enums from '../configs/enums';
import defaultValues from '../configs/defaultValues';
import injectLink from '../utils/inject.link';

import Register from './register/Register';
import Login from './login/Login';
import Cookies from './cookies/Cookies';
import NotFoundPage from '../pages/NotFoundPage';

/**
 * This is entry point for all widgets
 */
const Widgets = () => {

    const { widget } = useParams();
    const { i18n } = useTranslation();

    /**
     * Initialize state for send it to all widgets via GlobalContext
     * We should use state inside context to have possibility to update it 
     */
    const [globalCtx, setGlobalCtx] = useState({
        language: enums.langs[defaultValues.fallbackLanguage]?.backendMap,
        cultureId: defaultValues.fallbackCultureId,
        culture: defaultValues.fallbackCulture,
        country: defaultValues.fallbackCountry,
        acceptTerms: false,
        termsVersion: '',
        widgetHeight: null,
        widgetWidth: null,
    });
    // without memoization glovalCtx would create different object instances during re-rendering Register
    const memoState = useMemo(
        () => ({ globalCtx, setGlobalCtx }),
        [globalCtx]
    );

    /**
     * Handle params from url to send it to all widgets via context
     */
    const [searchParams] = useSearchParams();
    let params = {
        language: searchParams.get("language"),
        culture: searchParams.get("culture"),
    };
    // Filter object properties with empty values (not founded params)
    params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== null));

    const widgetContainerRef = useRef(null);
    const [widgetWidth, widgetHeight] = useSize(widgetContainerRef);

    /**
     * Handle language and initialize state
     */
    useEffect(() => {

        // Load fonts from pulse (Roboto, Droid, PT, etc..)
        injectLink('https://' + process.env.REACT_APP_LOCATION_PULSE + '/fonts/font.css', 'stylesheet');

        i18n.changeLanguage(params.language || defaultValues.widgetParams.language);

        if (params.language === enums.langs['ara'].key) {
            document.body.dir = 'rtl';

            /** 
             * Workaround for cookies widget
             * basically we use rtl.css for UI support such components like 'select', which have arrow on side and need to be changed for rtl
             * we need to find a better solution, because now rtl brokes the grid system
            */
            if (widget !== 'cookies') {
                // Bootstrap specific thing - we need to load different stylesheets for rtl languages
                import('bootstrap/dist/css/bootstrap.rtl.min.css');
            }
        }

        setGlobalCtx({
            ...globalCtx,

            language: enums.langs?.[params?.language]?.backendMap,
            // If culture is specified by params then use that, otherwise get culture by location
            cultureId: params.culture ? getCultureId(params.culture, enums.langs[params.language]?.backendMap) : cultureApi.getCultureId(enums.langs[params.language]?.backendMap),
            culture: params.culture ?? cultureApi.getCulture(),
            country: cultureApi.getUserCountry(),
            widgetWidth,
            widgetHeight,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Send every height changes to parent window and save size to globalCtx
     * This used to make iframe responsive and for nested components
     */
    useEffect(() => {
        if (widgetHeight && (widgetHeight !== globalCtx.widgetHeight)) {
            let dataForTargetPage = {
                name: 'easymarkets_widget_message_size',
                type: enums.messageTypes.system,
                height: widgetHeight,
                width: widgetWidth,
            };
            utils.sendMessageToFrameParent(dataForTargetPage);
        }

        // widgetHeight and widgetWidth can be updated before globalCtx finish initialization
        // to prevent losing data we use callback here
        setGlobalCtx(globalCtx => {
            return ({
                ...globalCtx,

                widgetWidth,
                widgetHeight,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetHeight, widgetWidth]);

    /**
     * based on useParams hook result, we expect what 'widget' will provide name of the widget
     * and map that with according Component
     * Otherwise we show NotFoundPage
     */
    let components = {
        NotFoundPage,
        register: Register,
        login: Login,
        cookies: Cookies,
    };
    const Widget = components[widget] ?? components['NotFoundPage'];

    return (
        // provide our memorized state to context
        // to consume state in nested components use useContext(GlobalContext)
        <GlobalContext.Provider value={memoState}>
            {/* widgetContainerRef assigned for useSize hook */}
            <div ref={widgetContainerRef}>
                <Widget />
            </div>
        </GlobalContext.Provider>
    );
};
export default Widgets;