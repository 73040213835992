import { useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import GlobalContext from '../../store/global-context';

import Attribution from '../../libs/attribution.lib';
import LegalDocuments from '../../libs/legal.documents';

import './Register.scss';

import Header from '../components/Header';
import RegisterForm from './RegisterForm';
import Social from '../components/Social';
import Sponsorship from '../components/Sponsorship';
import Trustpilot from '../components/Trustpilot';
import TermsConditions from '../components/TermsConditions';


const Register = () => {
    const { globalCtx, setGlobalCtx } = useContext(GlobalContext);

    const [searchParams] = useSearchParams();
    // Note: all values kept as strings
    let params = {
        gateway: searchParams.get("gateway"),
        layout: searchParams.get("layout"),
        title: searchParams.get("title"),
        showHeader: searchParams.get("showHeader"),
        showSocial: searchParams.get("showSocial"),
        showTrustpilot: searchParams.get("showTrustpilot"),
        showSponsorship: searchParams.get("showSponsorship"),
    };
    // Filter object properties with empty values (not found params)
    params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== null));

    // If we send object with null value - it'll cause an error on server side and utm assigning will fail
    let utmparams = [
        // { utm_source: searchParams.get("utm_source") },
        { utm_medium: searchParams.get("utm_medium") },
        // { utm_content: searchParams.get("utm_content") },
        // { utm_campaign: searchParams.get("utm_campaign") },
        // { utm_ef_channel: searchParams.get("utm_ef_channel") }
    ];

    Attribution.setGatewayId(params.gateway);

    const fetchAcceptTermsInfo = async () => {

        let acceptTermsInfo = await LegalDocuments.getAcceptTermsInfo('client-agreement', globalCtx.language, globalCtx.culture);
        // In this file we can do it directly to state
        setGlobalCtx((globalCtx) => {
            return {
                ...globalCtx,

                acceptTerms: acceptTermsInfo.acceptTerms,
                termsVersion: acceptTermsInfo.termsVersion
            };
        });

    };

    useEffect(() => {
        fetchAcceptTermsInfo();
    }, [globalCtx.culture]);

    return (
        <div id='registerWidget' className='pb-3'>

            {params.showHeader === 'true' ?
                <Header title={params.title} className="register-header" /> : null}

            <RegisterForm className="register-form" utmparams={utmparams} gatewayId={params.gateway}/>

            {params.showSocial === 'true' ?
                <Social /> : null}

            {params.showSponsorship === 'true' ?
                <><hr className='my-2' /><Sponsorship /></> : null}

            {params.showTrustpilot === 'true' ?
                // Don't use js conditions here to hide trustpilot. Otherwise it will be broken after rerender. 
                // probably script ijection every time after hidding may solve this, but imho it's better to leave with classes
                // 218px it's a value when Trustpilot is getting small enough to lose any point to use that. you will see only one word with grade
                <div className={`${globalCtx.widgetWidth > 218 ? 'd-block' : 'd-none'}`}>
                    <hr className='my-2' />
                    <Trustpilot lang={globalCtx.language} />
                </div> : null}

            <hr /><TermsConditions />
        </div>
    );
};

export default Register;