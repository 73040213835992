const termsConditions = {
    eu: 't_terms_conditions_eu',
    int: 't_terms_conditions_int',
}

const getTermsConditions = (culture) => {
    let usedCulture = null;
    // some cultures have same text
    switch (culture) {
        case 'eu':
        case 'au': usedCulture = 'eu';
            break;
        case 'int':
        case 'syc': usedCulture = 'int';
            break;
        default:
            usedCulture = null;
            break;
    }

    if (!usedCulture) {
        console.error('Terms And Conditions: Could not find culture ' + culture);
        return null;
    }

    return termsConditions[usedCulture];
};

export default getTermsConditions;