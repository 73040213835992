import { useState, useEffect, useRef } from "react";

import enums from "../../configs/enums";
import utils from "../../utils/utils";

const filterParams = (params) => {
    let filteredParams = {};

    Object.keys(params).filter(key => (params[key] !== undefined) && (params[key] !== '')).forEach(key => filteredParams[key] = params[key]);

    return filteredParams;
};

const getWidgetUrl = (domain, widgetName, params, options) => {
    // remove params with undefined values
    params = filterParams(params);

    return enums.domains[domain].url + `/widgets/${widgetName}?` + new URLSearchParams({ ...params, ...options }).toString();
};

const GenerateLink = ({ domain, widgetName, params, options }) => {

    const widgetContainerRef = useRef(null);
    const [widgetHeight, setWidgetHeight] = useState('100');

    const [widgetUrl, setWidgetUrl] = useState('');
    const [showMessage, setShowMessage] = useState({});

    // Used for have access from copy button click event
    const widgetUrlRef = useRef(null);
    const widgetCodeRef = useRef(null);

    useEffect(() => {
        subscribeForWidgetMessages();
    }, []);

    useEffect(() => {
        setWidgetUrl(getWidgetUrl(domain, widgetName, params, options));
    }, [domain, widgetName, params, options]);

    useEffect(() => {
        if (widgetName === 'cookies') {
            setShowMessage( {...showMessage, 'widgetPreviewCookies': true } );
        }
    }, [widgetName])

    const copyText = (ref) => {
        utils.copyText(ref.current.value)
            .then(() => {
                setShowMessage( {...showMessage, [ref.current.name]: true } );

                setTimeout(
                    // It's important to send an arrow function instead of directly state, to get state in execution moment
                    () => setShowMessage((showMessage) => { return { ...showMessage, [ref.current.name]: false }; }),
                    3000
                );
            });
    };

    const subscribeForWidgetMessages = () => {
        // Subscribe for messages from the frame
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
        window.addEventListener('message', (event) => {
            if (!event.data) return;

            // try to parse string data into JSON
            let data;
            try {
                if (typeof event.data !== 'object') {
                    data = JSON.parse(event.data);
                }
            } catch (error) {
                console.error("%cFailed to receive data from iframe: %c" + error, "font-weight: bold; text-decoration: underline;", "font-weight: normal;");
                console.error("%cReceived: %c" + event.data, "font-weight: bold; text-decoration: underline;", "font-weight: normal;");
            }

            // data.type basically means destination of the message
            // here we handle only system messages, so otherwise return
            // type 0: system messages 
            if (!data || data.type !== 0) {
                return;
            }
            // Include in reveived message name to filter out not related target window messages
            if (data && data.name?.includes("easymarkets_widget_message")) {
                switch (data.name) {
                    case "easymarkets_widget_message_size":
                        // we adjust size of the borders (2px) to iframe height
                        setWidgetHeight(data.height + 2);
                        break;
                    default:
                        console.warn('Received message have undefined or unknown name, skipping');
                        break;
                }
            }
        });
    };

    return (
        <div className="container">

            {showMessage['widgetPreviewCookies'] && <small className="form-text text-muted">Please note that in order to see a preview of this widget, you will need to clear your browser cookies if you have previously accepted them. It follows the same behavior as expected from a widget.</small>}
            <hr />
            <div className="d-flex mt-2">
                <iframe title="widget preview" className="m-auto w-100" src={widgetUrl} ref={widgetContainerRef} height={widgetHeight}></iframe>
            </div>
            <hr />
            <div className="input-group">
                <textarea value={widgetUrl} ref={widgetUrlRef} className="form-control m-0" name="widgetUrl" aria-label="With textarea" rows="4" readOnly></textarea>
                <button onClick={() => copyText(widgetUrlRef)} type="button" className="btn btn-outline-secondary">Copy</button>
            </div>
            {showMessage['widgetUrl'] && <small className="form-text text-muted">Text successfully copied.</small>}

            <div className="input-group mt-2">
                <textarea value={`<div id="easymarkets-tpw"></div>\n<script src="${enums.domains[domain].url}/webroot/easymarkets-tpw.js" iframe-src="${widgetUrl}" defer></script>`} ref={widgetCodeRef} className="form-control m-0" name="widgetCode" aria-label="With textarea" rows="6" readOnly></textarea>
                <button onClick={() => copyText(widgetCodeRef)} type="button" className="btn btn-outline-secondary">Copy</button>
            </div>
            {showMessage['widgetCode'] && <small className="form-text text-muted">Text successfully copied.</small>}
        </div>
    );
};

export default GenerateLink;