import getErrorMessage from "../configs/apiErrors";

/**
 * Calling general APIs from web-internal-apis project
 */
const WebApis = {
    /**
   * core method to call Apis
   * @param {*} params 
   * @returns 
   */
    Execute: async (params) => {

        console.debug(`Calling API:${params.api}, method:${params.method}`);

        let api = params.api;
        let requestOptions = {
            method: params.method,
        };

        let apiUrl = 'https://' + process.env.REACT_APP_LOCATION_WEBAPI + api;

        let url = `${apiUrl}`;

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            console.error(`Error in method:${params.method}, Status:${response.status}, Message:${getErrorMessage(response.status)}`);
            return false;
        }

        let result = await response.text();
        //console.log(`Result: \n ${JSON.stringify(result)}`);


        return result;

    },


    getLegalDocuments: async (language = 'en') => {

        let params = {
            method: "GET",
            api: `/getLegalDocuments/${language}/`,
        }

        let result = JSON.parse(await WebApis.Execute(params));

        if (!result) {
            console.log(`Api error, aborting`);
            return false;
        }
        return result;
    }
};

export default WebApis;