import { useImperativeHandle, forwardRef } from "react";
import { createPortal } from "react-dom";

import useModal from "../../hooks/useModal";

import Modal from 'react-bootstrap/Modal';

/**
 * Modal component.
 * 
 * To toggle modal use refs, e.g.:
 *      const myModalRef = useRef();
 *      myModalRef.current.toggleModal();
 * 
 * @param {*} ref - Reference to use toggleModal functionality (https://reactjs.org/docs/forwarding-refs.html)
 * 
 * @param {JSX} title - will be displayed at the top of modal window
 * @param {JSX} content - will be displayed as content of window
 * @param {Number} timeout - optional, delay in milliseconds
 * @param {Element} target - optional, element - target of modal window, default document.body
 * @param {Boolean} closeButton - optional, default is false, display close button in header
 * @param {Boolean} fullscreen - optional, default is false
 * @param {String} size - optional, size of modal window can be 'sm', undefined = default,'lg', 'xl'
 */
const WidgetsModal = forwardRef((props, ref) => {
    const { title, content, timeout, fullscreen, closeButton, size, target, onVisibilityChange } = props;

    const { showModal, toggleModal } = useModal();

    useImperativeHandle(ref, () => ({
        toggleModal(newState) {
            toggleModal(newState)
        },
        isVisible: showModal
    }));

    const onHideEvent = () => {
        toggleModal();
        if (typeof onVisibilityChange === 'function') {
            onVisibilityChange();
        }
    };

    if (showModal && timeout) {
        setTimeout(toggleModal(), timeout);
    }

    return showModal
        ? createPortal(
            <Modal show={true} fullscreen={fullscreen} onHide={onHideEvent} size={size} >
                {title || closeButton ?
                    <Modal.Header closeButton={closeButton}>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    : null
                }
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    {content}
                </Modal.Body>
            </Modal>,
            target ?? document.body,
        )
        : null;
});

export default WidgetsModal;