import countries from "../configs/countries";
import { cultures } from "../configs/enums";
import countryIds from "../configs/countryIds";

/**
 * List of depricated country ids
 *
 * Used when countries replaced but users
 * with old country able to login to see
 * details etc.
 */
const depricatedCountries = {
    224: countryIds.UK,
};


/**
 * Geo Location Data
 *
 * Parses geo string set in cookie by server to provide front
 * end access to geo location data.
 * 
 * todo: convert to functional style
 */
export default class GeoData {

    // example geoString: '123.45.67.890:CY:Cyprus:eu:Asia/Nicosia';
    constructor(geoString = '') {

        // Set countries array
        this.list = countries || [];

        // Parse geo data to object
        const data = geoString.split(':');

        // todo: can we use context here
        const defaultCulture = 'eu';

        // Cultures from site data
        const availableCulture = Object.keys(cultures) || [];

        this.data = {
            clientIP: data[0] || '',
            countryId: '',
            countryIsoCode: data[1] || '',
            countryName: data[2] || '',
            countryCulture: (availableCulture.includes(data[3]) ? data[3] : defaultCulture),
            countryCode: '',
            timeZone: data[4] || '',
            timeZoneOffset: 0,
        };

        // Set country id / code
        const country = this.find(this.data.countryIsoCode);

        this.data.countryId = country.countryId || '';
        this.data.countryCode = country.countryCode || '';

        // Set timezone offset
        if (this.data.timeZone && this.data.timeZone !== 'UTC') {
            this.data.timeZoneOffset = this.getCustomZoneOffset(this.data.timeZone);
        }
    }

    /**
     * Find by ID
     *
     * ID can be either country iso code string or country
     * id integer, returns country object if found.
     *
     * @param {string|integer} id ID to use in country search.
     * @return {object} result Country object.
     */
    find(id) {

        const selector = !isNaN(id) ? 'countryId' : 'iso';

        const result = this.list.find(country => {
            return country[selector] === (depricatedCountries[id] || id)
        });

        return result || {};
    }

    /**
     * Get Custom Zone Offset
     *
     * Takes timezone string and finds offset between given
     * timezone and GMT.
     *
     * @param {string} timezone Timezone to check (ex. 'Asia/Nicosia').
     * @return {integer} offset Offset value between GMT / given Timezone.
     */
    getCustomZoneOffset(timezone) {

        let offset = 0;

        if (timezone) {

            let dateOptions = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'Europe/London',
            };

            try {
                const dateGmt = new Date().toLocaleDateString('en', dateOptions);
                const dateLocal = new Date().toLocaleDateString('en', { ...dateOptions, ...{ timeZone: timezone } });

                offset = (new Date(dateLocal) - new Date(dateGmt)) / (1000 * 60 * 60);
            }
            catch (e) {
                offset = 0;
            }
        }

        return offset;
    }

}
