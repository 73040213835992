const minPasswordLength = 6;

// use key, value objects to avoid React 'key not found' warnings
const langs = {
    'eng': {
        key: 'eng',
        value: 'English',
        backendMap: 'en'
    },
    'spa': {
        key: 'spa',
        value: 'Spanish',
        backendMap: 'es'
    },
    'deu': {
        key: 'deu',
        value: 'German',
        backendMap: 'de'
    },
    'ita': {
        key: 'ita',
        value: 'Italian',
        backendMap: 'it'
    },
    'por': {
        key: 'por',
        value: 'Portuguese',
        backendMap: 'pt'
    },
    'zho': {
        key: 'zho',
        value: 'Chinese',
        backendMap: 'zh'
    },
    'ara': {
        key: 'ara',
        value: 'Arabic',
        backendMap: 'ar'
    },
    'pol': {
        key: 'pol',
        value: 'Polish',
        backendMap: 'pl'
    },
    'jpn': {
        key: 'jpn',
        value: 'Japanese',
        backendMap: 'ja'
    },
};

export const cultures = {
    'eu': {
        key: 'eu',
        value: 'European'
    },
    'int': {
        key: 'int',
        value: 'International'
    },
    'syc': {
        key: 'syc',
        value: 'Seychelles'
    },
    'au': {
        key: 'au',
        value: 'Australian'
    }
};

const layouts = {
    'vertical': {
        key: 'vertical',
        value: 'Vertical'
    },
    'horizontal': {
        key: 'horizontal',
        value: 'Horizontal'
    }
};

const options = {
    'showHeader': {
        key: 'showHeader',
        value: 'Show Header',
    },
    'showSocial': {
        key: 'showSocial',
        value: 'Show Social',
    },
    'showTrustpilot': {
        key: 'showTrustpilot',
        value: 'Show Trustpilot',
    },
    'showSponsorship': {
        key: 'showSponsorship',
        value: 'Show Sponsorship',
    },
};
// Since we use .value as option text, we need to define additional property to keep human readable values.
const domains = {
    "connect.easymarkets.com": {
        key: "connect.easymarkets.com",
        value: "connect.easymarkets.com",
        url: "https://connect.easymarkets.com",
        environments: ["local", "prp", "prod"]
    },
    "prpconnect.easymarkets.com": {
        key: "prpconnect.easymarkets.com",
        value: "prpconnect.easymarkets.com",
        url: "https://prpconnect.easymarkets.com",
        environments: ["local", "prp"]
    },
    // Alternative links is out of scope for now
    // "connect.emarkets-global.com": {
    //     key: "connect.emarkets-global.com",
    //     value: "connect.emarkets-global.com",
    //     url: "https://connect.emarkets-global.com",
    //     environments: ["local", "prp", "prod"]
    // },
    // "prpconnect.emarkets-global.com": {
    //     key: "prpconnect.emarkets-global.com",
    //     value: "prpconnect.emarkets-global.com",
    //     url: "https://prpconnect.emarkets-global.com",
    //     environments: ["local", "prp"]
    // },
    "lcl-connect.easymarkets.com": {
        key: "lcl-connect.easymarkets.com",
        value: "lcl-connect.easymarkets.com",
        url: "https://lcl-connect.easymarkets.com:3000",
        environments: ["local"]
    },
};

/**
 * No special meaning for this, just a list for testing purposes
 */
 export const tester = {
    '1': {
        key: '1',
        value: 'Show Test',
    },
    '2': {
        key: '2',
        value: 'Just dummy test',
    },
    'demo': {
        key: 'demo',
        value: 'for demo ofc',
    },
};

/**
 * SocialMediaTypes which used in eM API
 */
export const socialMediaTypes = {
    google: 1,
    facebook: 2,
    apple: 3
};


export const locales = {
    'ara': 'ar',
    'deu': 'de',
    'eng': 'en-GB',
    'ita': 'it',
    'jpn': 'ja',
    'pol': 'pl-PL',
    'por': 'pt-PT',
    'spa': 'es-ES',
    'zho': 'zh-CN'
};

export const cultureEnum = {
    au: 'au',
    int: 'int',
    eu: 'eu',
    syc: 'syc',
};

// Basically messageTypes means destination of the message from iframe
export const messageTypes = {
    system: 0, // Used for internal communication between easymarkets-tpw.js and iframe
    parentWindow: 1 // Used for implementations
};

export const cookieScreens = {
    hidden: 0,
    policy: 1,
    settings: 2
};
export const cookieTypeEnum = {
    functional :["SOCS","CONSENT","AEC","auth","siteLanguage","EMGEO2"],
	performance: ["OTZ", "_ga", "f5avr0502194396aaaaaaaaaaaaaaaa_cspm_", "TS01", "_gid", "_dc_gtm_UA-69727729-1"],
    behavioural :["DV","Hm_Ipvt","1P_JAR","Hm_Ivt","NID","_gcl_au","_fbp","ln_or"]
};


const enums = {
    minPasswordLength,
    langs,
    cultures,
    layouts,
    options,
    tester,
    domains,
    socialMediaTypes,
    locales,
    cultureEnum,
    messageTypes,
    cookieScreens,
    cookieTypeEnum
};

export default enums;