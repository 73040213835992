import { useTranslation } from "react-i18next";

import { cookieScreens } from '../../configs/enums';

const CookiesPolicy = ({ onAccept, setAcceptedCookies, onOpenSettingsClick }) => {
    const { t } = useTranslation();

    const sponsorshipLogoPath = '/cookies/sponsorship-2023.png';

    const acceptAllCookies = () => {
        const _acceptedCookies = {
            functional: true,
            performance: true,
            behavioural: true
        };

        setAcceptedCookies(_acceptedCookies);
        onAccept(_acceptedCookies);
    }

    return (
        <div id="cookiesPolicy" className="row m-0 pt-0 pt-xl-4">
            <div className="col-xxl-7 pb-4 pb-xxl-0">
                <div className="col-xl-12 align-items-center justify-content-center d-xl-none d-flex">
                    <div className="sponsorship-logo p-4">
                        <img className="w-100" alt="Official sponsor of Real Madrid." src={sponsorshipLogoPath} />
                    </div>
                </div>
                <p className="fw-bold" style={{ fontSize: 17, marginBottom: '1.25em' }}>{t('This Website uses Cookies')}</p>
                <p style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: t('To ensure your convenience, our website uses cookies that create faster page load times, smoother navigation, and a seamless browsing experience. Cookies also help us strengthen security and improve website performance by enhancing our analytics. To proceed and consent to our use of cookies, click <strong>"Enable All"</strong>') }} />
                <p>{t('You can find out more about which cookies we use or switch them off in')} <a id="cookie_settings_link" data-next-screen={cookieScreens.settings} onClick={onOpenSettingsClick}>{t('Cookies Settings')}</a></p>
            </div>
            <div className="col-xxl-5 d-flex align-items-center justify-content-center">
                <div className="row">
                    <div className="col-xl-12 align-items-center justify-content-center d-xl-flex d-none sponsorship-logo">
                        <div className="sponsorship-logo p-4">
                            <img className="w-100" alt="Official sponsor of Real Madrid." src={sponsorshipLogoPath} />
                        </div>
                    </div>
                    <div className="col-xl-12 d-flex align-items-center">
                        <button className="py-2 btn btn-success btn-consent" style={{ fontSize: 14, fontWeight: 500 }} onClick={acceptAllCookies} >{t('Enable All Cookies and Close')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookiesPolicy;