import { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from "react-i18next";

import jwt_decode from 'jwt-decode';

import utils from '../../utils/utils';
import { getLocaleISOFromI18N } from '../../utils/getLocale';
import injectScript from '../../utils/inject.script';
import Apis from '../../apis/Apis';
import GlobalContext from "../../store/global-context";

import { Row, Col } from 'react-bootstrap';
import WidgetsModal from './WidgetsModal';
import CountrySelect from './CountrySelect';
import CompleteEmail from './CompleteEmail';

import getErrorCode from '../../configs/apiErrorCode';
import getErrorMessage from '../../configs/apiErrors';
import countries from '../../configs/countries';
import { socialMediaTypes } from '../../configs/enums';

/**
 * This constant activate feature which allow to sign up without email in Facebook account.
 * Instead of error message user will see a screen with suggestion to complete email (as we do for country select)
 */
const allowProceedFacebookSignupWithoutEmail = false;

const Social = () => {
    const { globalCtx, setGlobalCtx } = useContext(GlobalContext);
    const { t, i18n } = useTranslation();

    const selectCountryModalRef = useRef();
    const registrationCompleteModalRef = useRef();
    const completeEmailModalRef = useRef();

    const [registrationCompleteModalMsg, setRegistrationCompleteModalMsg] = useState({});

    const [disableSubmit, setDisableSubmit] = useState(false);

    const [userInfo, setUserInfo] = useState({
        email: '',
        firstName: '',
        lastName: '',

        socialMediaType: null,
        socialMediaToken: '',

        country: globalCtx.country,
        locale: getLocaleISOFromI18N(i18n.language)
    });

    useEffect(() => {
        injectScript('https://accounts.google.com/gsi/client', initGoogleLogin);
        injectScript('https://connect.facebook.net/en_US/sdk.js', initFacebookLogin);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Google loading pretty long, so we will wait for it
        if (!window.google) return;
        // Re-render google button when we change or init language;
        renderGoogleButton(document.querySelector('#googleLoginButton'), document.querySelector('.google-btn-container')?.offsetWidth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.locale]);

    useEffect(() => {
        setUserInfo({
            ...userInfo,
            country: globalCtx.country
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalCtx.country]);

    const handleCountryChange = (event) => {
        const country = utils.getCountryByCountryId(event.target.value);
        const newCulture = utils.getCultureByCountryId(event.target.value, globalCtx.language);

        if (newCulture) {
            setGlobalCtx({
                ...globalCtx,
                country,
                cultureId: newCulture.cultureId,
                culture: newCulture.culture
            });
        }
    };
    const handleEmailCompleteChange = (event) => {
        setUserInfo({ ...userInfo, email: event.target.value });
    };

    /**
     * Error message will be taken from configs/apiErrors.js
     * and putted into modal message state
     * 
     * @param {*} errorCode 
     */
    const updateRegistationCompleteModalMessageWithErrorCode = (errorCode) => {
        setRegistrationCompleteModalMsg({
            title: t('sign_up_error'),
            content: t(getErrorMessage(errorCode))
        });
    };

    let googleContainerWidth;
    const initGoogleLogin = () => {
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_EASYMARKETS_SOCIAL_GOOGLE_CLIENT_ID,
            callback: googleAuthCallback
        });

        // initial render
        renderGoogleButton(document.querySelector('#googleLoginButton'), document.querySelector('.google-btn-container')?.offsetWidth);
        // rerender on every resize
        window.addEventListener('resize', () => {
            const element = document.querySelector('.google-btn-container');

            if (element && (googleContainerWidth !== element.offsetWidth)) {
                googleContainerWidth = element.offsetWidth;
                renderGoogleButton(document.querySelector('#googleLoginButton'), googleContainerWidth);
            }
        });
    };
    const googleAuthCallback = async (res) => {

        const socialMediaToken = res.credential;
        const payload = await jwt_decode(socialMediaToken);

        let _userInfo = {
            email: payload.email,
            firstName: payload.given_name,
            lastName: payload.family_name,

            country: globalCtx.country,

            socialMediaType: socialMediaTypes['google'],
            socialMediaToken
        };

        setUserInfo({ ...userInfo, ..._userInfo });

        socialAuthenticationCallback(_userInfo);
    };
    const renderGoogleButton = (element, width) => {
        if (!element || !width) return;

        const options = {
            // google adding 24px to width, which we don't need
            width: width - 24,

            locale: userInfo.locale,

            type: "standard",
            shape: "rectangular",
            theme: "outline",
            text: "signin",
            size: "large",
            logo_alignment: "left",
        };

        window.google.accounts.id.renderButton(element, options);
    };

    const initFacebookLogin = () => {
        window.FB.init({
            appId: process.env.REACT_APP_EASYMARKETS_SOCIAL_FACEBOOK_APP_ID, // eM
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v17.0'
        });
    };
    const facebookAuth = async () => {
        const { FB } = window;

        FB.login((response) => {

            if (response.status === 'connected') {

                // Fail if no granted email
                let granted = (response.authResponse.grantedScopes || '').split(',');
                if (!granted.includes('email')) {
                    updateRegistationCompleteModalMessageWithErrorCode('fb_email_not_shared');
                    registrationCompleteModalRef.current.toggleModal();
                    console.debug('Facebook: failed_email_permission');

                    return false;
                }

                let token = FB.getAccessToken();
                if (token) {
                    FB.api('/me', { fields: 'last_name, first_name, email' }, (payload) => {
                        let _userInfo = {
                            email: payload.email || null,
                            firstName: payload.first_name || null,
                            lastName: payload.last_name || null,

                            country: globalCtx.country,

                            socialMediaType: socialMediaTypes['facebook'],
                            socialMediaToken: token,
                        };

                        setUserInfo({ ...userInfo, ..._userInfo });

                        socialAuthenticationCallback(_userInfo);
                        console.debug('Facebook success authenticated');
                    });

                    return true;
                }
                else {
                    updateRegistationCompleteModalMessageWithErrorCode(0);
                    registrationCompleteModalRef.current.toggleModal();

                    console.debug('Facebook: failed_to_fetch_token');
                    return false;
                }
            }
            else {
                console.debug('Facebook: popup_closed_by_user');
            }

        }, {
            scope: 'public_profile,email',
            return_scopes: true,
            auth_type: 'rerequest'
        });
    };


    const socialAuthenticationCallback = async (_userInfo) => {

        // Try Login API to check if user already have account
        let response = await Apis.Login(_userInfo);

        // If user successfully do login, then it's mean we don't need to try create a new account
        if (!response.error) {
            updateRegistationCompleteModalMessageWithErrorCode("social_account_already_exist");
            registrationCompleteModalRef.current.toggleModal();

            return null;
        }
        if (parseInt(response.error) === getErrorCode('login_with_password_to_activate_social')) {
            updateRegistationCompleteModalMessageWithErrorCode(response.error);
            registrationCompleteModalRef.current.toggleModal();

            console.debug('User with this email already exist, need to login with credential and activate from eM');

            return null;
        }

        selectCountryModalRef.current.toggleModal(true);
    };

    const createOrUpdateAccount = async () => {
        setDisableSubmit(true);

        // FB user can grant all access, but if doesn't have an email we handle it differently
        if (!userInfo.email && userInfo.socialMediaType === socialMediaTypes['facebook']) {
            if (allowProceedFacebookSignupWithoutEmail) {
                completeEmailModalRef.current.toggleModal(true);
            } else {
                updateRegistationCompleteModalMessageWithErrorCode("facebook_doesnt_have_an_email");
            }
        }

        const response = await Apis.CreateOrUpdateAccount({ 
            ...userInfo,
            countryId: userInfo.country.countryId,
            cultureId: globalCtx.cultureId 
        }, true);

        if (!response.error) {
            setRegistrationCompleteModalMsg({
                title: t('thank_you_for_signing_up'),
                content: t('successful_signup_with_sent_email')
            });
        } else {
            updateRegistationCompleteModalMessageWithErrorCode(0);

            console.warn('error with eM api: CreateOrUpdateAccountWithSocial()');
        }

        selectCountryModalRef.current.toggleModal(false);
        completeEmailModalRef.current.toggleModal(false);

        registrationCompleteModalRef.current.toggleModal(true);

        setDisableSubmit(false);
    };

    return (
        <>
            <Row className='register-social'>
                <Col>
                    <div className="social-title d-flex">
                        <hr className='w-100' />
                        <p className="title-wline m-auto">{t('or_connect_with')}</p>
                        <hr className='w-100' />
                    </div>
                    <div className="social-buttons row text-nowrap">
                        <div className="google-btn-container col-6 d-flex justify-content-center">
                            <div id="googleLoginButton"></div>
                        </div>

                        <div className="facebook-btn-container col-6 d-flex justify-content-center">
                            <button id='facebookLoginButton' onClick={facebookAuth} type="button" className="d-flex align-items-center facebook-login w-100">
                                <img src="/social/facebook.svg" alt="facebook logo" />
                                <div className='w-100'></div>
                                Facebook
                                <div className='w-100'></div>
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
            <WidgetsModal ref={completeEmailModalRef} closeButton content={
                <CompleteEmail
                    value={userInfo.email ?? ''}
                    onChange={handleEmailCompleteChange}
                    submitText={t('sign_up')}
                    onSubmit={createOrUpdateAccount}
                    disableSubmit={disableSubmit}
                    setDisableSubmit={setDisableSubmit}
                />
            } />
            <WidgetsModal ref={selectCountryModalRef} closeButton title={t('coutry_of_residence')} content={
                /**
                 * @todo: move country selection to separate component to reuse in other places
                 */
                <CountrySelect
                    value={globalCtx.country.countryId ?? ''}
                    onChange={handleCountryChange}
                    items={countries}
                    submitText={t('sign_up')}
                    onSubmit={createOrUpdateAccount}
                    disableSubmit={disableSubmit}
                />
            } />
            <WidgetsModal ref={registrationCompleteModalRef} closeButton title={registrationCompleteModalMsg.title} content={registrationCompleteModalMsg.content} />

        </>
    );
};

export default Social;