/**
 * Required props are:
 * - value
 * 
 * Available options is: 
 * - type
 * - label
 * - message
 * - placeholder
 * 
 * Rest props will be applied on container
 */
export default function TextField(props) {
    const { value, type, label, message, placeholder, onChange, required, autoComplete, ...restProps } = props;

    return (
        <div className="form-group" {...restProps}>
            { label && <label>{label}</label> }
            <input value={value} type={type} placeholder={placeholder} onChange={onChange} required={required} autoComplete={autoComplete} className="form-control" />
            { message && <small className="form-text text-muted">{message}</small> }
        </div>
    );
}