/**
 * Strings with underscores (_) is keys for translations
 */
const apiErrors = {
    0: "general_error",
    2: "An error occurred while trying to process your request. For more assistance, please contact our customer support team.",
    64: "login_with_password_to_activate_social",
    // I'm not sure about this, but appears if socialMediaType is undefined
    65: "Invalid social media type",
    67: "Invalid credentials",
    68: "social_account_already_connected",
    401: "Unauthorized",
    412: "Type in your first name. Only A-Z or a-z characters and spaces are allowed.",
    413: "Type in your last name. Only A-Z or a-z characters and spaces are allowed.",
    418: "Please enter all required fields and try again",
    430: "It looks like you already have a trading account",
}
/**
 * Use strings as keys here to avoid collisions with apiErrors
 * Some modified, external error messages can be placed here 
 */
const externalApiErrors = {
    "fb_email_not_shared": "fb_email_not_shared",
    "facebook_doesnt_have_an_email": "facebook_doesnt_have_an_email",
    "social_account_already_exist": "social_account_already_exist",
}

const getErrorMessage = (error) => {
    return apiErrors[parseInt(error)] || externalApiErrors[error] || apiErrors[0];
}

export default getErrorMessage;