import WebApis from "../apis/WebApis";
import Apis from "../apis/Apis";

/**
 * to use, uncomment one of the following API calls
 *
 * @param {*} params 
 * @returns 
 */
const TestApi = (params) => {
    // CheckExistUserName
    //let result = Apis.CheckExistUserName('test@test.com');

    // GetEuRiskPercentage
    //let result = Apis.GetEuRiskPercentage();

    let result = WebApis.getLegalDocuments('zh-hans');


    // CreateOrUpdateAccount
    // let input = {
    //     userName: 'test@test.com',
    //     password: 'the837AHDDE&#^!$$!A',
    //     countryId: 55,
    //     firstName: "tnetetet",
    //     lastName: "tetetetet",
    //     countryCode: 357,
    //     phone1: "823809217",
    // };
    // let result = Apis.CreateOrUpdateAccount(input, 'en');

    if (!result) {
        console.log('Some error happened');

    }
    console.log(`Api returned ${result}`);
    return 'all good results';
};

export default TestApi;
