import { useTranslation } from "react-i18next";

import countryIds from "../configs/countryIds";
import { cultureEnum } from "../configs/enums";

const CultureRedirectLib = {
    CultureRedirectContent: function (countryId, culture) {
        const { t } = useTranslation();

        if (countryId == countryIds.UK) {
            return (<>
                <p dangerouslySetInnerHTML={{ __html: t('uk_regulatory_purposes_redirection') }}></p>
                <p dangerouslySetInnerHTML={{ __html: t('uk_regulatory_falls_outside') }}></p>
                <p dangerouslySetInnerHTML={{ __html: t('uk_regulatory_confirmation') }}></p>
            </>);
        }

        switch (culture) {
            case cultureEnum.eu:
            case cultureEnum.au:
                return (<>
                    <p dangerouslySetInnerHTML={{ __html: t('regulatory_purposes_redirection') }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t('continue_open_account') }}></p>
                </>);
            case cultureEnum.int:
            case cultureEnum.syc:
                return (<>
                    <p dangerouslySetInnerHTML={{ __html: t('regulatory_purposes_redirection') }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t('continue_open_account_terms_conditions') }}></p>
                </>);
            default:
                return 'Something went wrong';
        }
    },

    getSiteUrl: function (culture) {
        return `https://${process.env.REACT_APP_EM_BASE_URL}/${culture}`;        
    },

    getCompany: function (culture) {
        let company = 'Easy Forex Trading Ltd';
        switch (culture) {
            case cultureEnum.eu:
                company = 'Easy Forex Trading Ltd';
                break;
            case cultureEnum.au:
                company = 'Easy Markets Pty Ltd';
                break;
            case cultureEnum.int:
                company = 'EF Worldwide Ltd - British Virgin Islands';
                break;
            case cultureEnum.syc:
                company = 'EF Worldwide Ltd - Seychelles';
                break;
            default:
                break;
        }
        return company;

    },

    getCultureRedirectUrls: (culture) => {
        return `https://www.easymarkets.com/${culture}/about/contact-us`;
    }


};

export default CultureRedirectLib;