
import DocCookies from "../libs/doc.cookies";
import GeoData from "../libs/geo.lib";
import getCultureId from "./cultureIds";
import defaultValues from "./defaultValues";
import countries from "./countries";

// parse cookie and create object
const parseCookie = () => {
    // Geo Location Data from Cookie
    let emGeoCookie = DocCookies.getItem(defaultValues.geoIPCookieName);
    console.debug(`cookie: ${emGeoCookie}, name:${defaultValues.geoIPCookieName}, cookie: ${DocCookies.getItem(defaultValues.geoIPCookieName)}`);

    // Geo Location Data for Local Testing
    if (!emGeoCookie) {
        emGeoCookie = defaultValues.geoIPCookieValue;
    }

    // Init Geo Data
    let geo = new GeoData(emGeoCookie);

    return geo;

};


const getCultureIdFromCookie = (language) => {

    let geo = parseCookie();

    let cultureId = getCultureId(geo.data.countryCulture, language);

    return cultureId;
};

const getCulture = () => {

    let geo = parseCookie();

    return geo.data.countryCulture;
};

const getCountryIsoCode = () => {

    let geo = parseCookie();

    return geo.data.countryIsoCode;
};

const getUserCountry = () => {
    let userCountryISO = cultureApi.getCountryIsoCode();

    return countries.filter((country) => country.iso === userCountryISO)?.[0] ?? defaultValues.fallbackCountry;
};


const cultureApi = {
    getCultureId: getCultureIdFromCookie,
    getCulture: getCulture,
    getCountryIsoCode: getCountryIsoCode,
    getUserCountry: getUserCountry
};

export default cultureApi;