/**
 * DocCookies implementation with initial settings
 *
 * This library is based on EM project.( see doc.cookies.js)
 * LND project webroot/global/scripts/global.js
 * 
 * https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
 */

// Set global defaults
const LOCAL = window.location.hostname;
const GLOBAL = '.easymarkets.com'; // temporarily hardcoded until we don't have easymarkets.data
const PATH = '/';

const DocCookies = {

	/**
	 * Get cookie by key
	 *
	 * @param {string} key Key to search.
	 * @return {string}
	 */
	getItem: function (key) {
		let cookie = document.cookie
			.split('; ')
			.find((row) => row.startsWith(`${key}=`))
			?.split('=')[1];

		if (typeof cookie === 'undefined') {
			return null;
		}

		return decodeURIComponent(cookie);
	},

	setItem: function (sKey, sValue, vEnd, sPath, sDomain, sameSite, bSecure) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
		let sExpires = "";
		if (vEnd) {
			switch (vEnd.constructor) {
				case Number:
					sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
					break;
				case String:
					sExpires = "; expires=" + vEnd;
					break;
				case Date:
					sExpires = "; expires=" + vEnd.toUTCString();
					break;
				default:
					console.error("Invalid cookie expiration: " + vEnd);
					return false;
			}
		}
		document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue)
			+ sExpires
			+ (sDomain ? ("; domain=") + sDomain : "")
			+ (sPath ? ("; path=" + sPath) : "")
			+ (bSecure ? ("; secure") : "")
			+ (sameSite ? ("; SameSite=" + sameSite) : "");
		return true;
	},

	/**
	 * Remove cookie by key.
	 *
	 * Removes both local and global domain cookies of given key.
	 *
	 * @param {string} key Key to search and remove.
	 * @return {boolean} Returns true if either cookie remove, false if neither found
	 */
	removeItem: function (key) {
		const _removeItem = (sKey, sPath, sDomain) => {

			if (!DocCookies.getItem(sKey)) { return false; }
			document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
			return true;
		}

		const removeGlobal = _removeItem(key, PATH, GLOBAL);
		const removeLocal = _removeItem(key, PATH, LOCAL);


		return removeGlobal || removeLocal;
	},

};

export default DocCookies;
