/**
 * Required props are:
 * - options
 * - value
 * 
 * Available options is: 
 * - label
 * - message
 * - placeholder
 * 
 * Rest props will be applied on container
 */
export default function SelectBox(props) {
    const { value, options, label, message, onChange, disableDefault, disabled, ...restProps } = props;

    return (
        <div className="form-group" {...restProps}>
            { label && <label>{label}</label> }
            <select value={value} onChange={onChange} className="form-select" disabled={disabled}>
                { !disableDefault && <option value='DEFAULT'></option>}
                { options }
            </select>
            { message && <small className="form-text text-muted">{message}</small> }
        </div>
    );
}